import { gql } from "@apollo/client";

export const GET_SIGNUP_COUNTRIES = gql`
query SignUpCountries{
    signUpCountries{
        id,
        dialCode,
        openForWithdrawal,
    }
}
`

export const GET_WITHDRAWAL_COUNTRIES = gql`
query WithdrawalCountries{
    withdrawalCountries{
        id,
        dialCode,
        openForWithdrawal,
    }
}
`