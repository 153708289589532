
import "../AuthMobile.less";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useAuth } from "../../../hooks/auth/AuthContext";

interface Props {
    backToFirstPage: () => void;
}

const RegisterMobileStep3 = ({ backToFirstPage }: Props) => {
    const navigate = useNavigate();

    const goHomePage = () => {
        navigate('/home');
    }

    return (
        <>
            <div className="m-register-form-title"><FormattedMessage id={'AUTH_REGISTER_STEP3'} /></div>
            <div className="m-register-form-item">
                <FormattedMessage id={'AUTH_REGISTER_SUCCESS'} />
            </div>
            <Button block type="primary" htmlType="submit" className="m-register-btn" onClick={goHomePage} >
                <FormattedMessage id={'AUTH_REGISTER_BACK_FIRST'} />
            </Button>
        </>
    );
};

export default RegisterMobileStep3;
