import { gql } from '@apollo/client';

export const GET_WIN_LOSS_REPORT_DETAILS = gql`
query WinLossReportDetails($pageNo:Int,$pageSize:Int!, $winLossReportDetailInput:WinLossReportDetailInput){
    winLossReportDetails(pageNo:$pageNo,pageSize:$pageSize, winLossReportDetailInput:$winLossReportDetailInput){
        count(winLossReportDetailInput:$winLossReportDetailInput),
        winLossReports{      
            id,
            userName,
            gameProvider,
            product,
            gameType,
            gameName,
            gameCode,
            gameBetDate,
            gameEndDate,
            roundId,
            betAmount,
            winAmount,
            winLossAmount,
            progressiveBetAmount,
            progressiveWinAmount,
            validBetAmount,
            validWinLossAmount,
            balanceBefore,
            balanceAfter,
            status
        }
    }
}
`;

export const GET_TOTAL_SUMMARY = gql`
query WinLossReportTotalSummary($winLossReportDetailInput:WinLossReportDetailInput!){
    winLossReportTotalSummary(winLossReportDetailInput:$winLossReportDetailInput){
        totalBetAmount,
        totalWinLossAmount,
        totalValidBetAmount,
        totalValidWinLossAmount
    }
}
`;

export const GET_WIN_LOSS_REPORT_DETAIL_BY_ID = gql`
query WinLossReportDetail($id:String!){
    winLossReportDetail(id:$id){
        id,
        accountId,
        product,
        productType,
        validForRebate,
        gameType,
        gameCode,
        gameBetDate,
        gameEndDate,
        currency,
        betAmount,
        winAmount,
        winLossAmount,
        progressiveBetAmount,
        progressiveWinAmount,
        validBetAmount,
        validWinLossAmount,
        jackpotComm,
        jackpotWin,
        firstPrize,
        balanceBefore,
        balanceAfter,
        createdAt,
        betId
    }
}
`;

export const GET_WIN_LOSS_REPORT_DETAILS_EXPORT = gql`
query WinLossReportDetailsExport($winLossReportDetailInput:WinLossReportDetailInput){
    winLossReportDetailsExport(winLossReportDetailInput:$winLossReportDetailInput){
        status,
        csv
    }
}
`;

export const GET_GAME_PROVIDERS = gql`
query GameProviders{
    gameProviders{
        id,
        name
    }
}
`;

export const GET_PRODUCT = gql`
query Products{
    products{
        id
    }
}
`;