import { FormattedMessage, useIntl } from 'react-intl';
import '../Dashboard.less';
import { WalletOutlined } from '@ant-design/icons';

interface Props {
    tab: string;
}

const DashboardTabName = ({ tab }: Props) => {
    const intl = useIntl();
    let tabIcon: any;
    let tabName = '';
    switch (tab) {
        case 'home':
            tabIcon = <div className='home-icon' />;
            tabName = 'DASHBOARD_HOME';
            break;
        case 'member':
            tabIcon = <div className='member-icon' />;
            tabName = 'DASHBOARD_MEMBER';
            break;
        case 'withdraw':
            tabIcon = <div className='withdrawal-icon' />;
            tabName = 'DASHBOARD_MENU_WITHDRAW';
            break;
        case 'winloss':
            tabIcon = <div className='winloss-icon' />;
            tabName = 'DASHBOARD_MENU_WINLOSS';
            break;
        case 'comm-report':
            tabIcon = <div className='report-icon' />;
            tabName = 'DASHBOARD_MENU_COMM_REPORT';
            break;
        case 'profile':
            tabIcon = <div className='profile-icon' />;
            tabName = 'DASHBOARD_PROFILE';
            break;
        default:
            return <></>;
    }

    return <div className='dashboard-tab-name-container' >
        {tabIcon}
        <div className='dashboard-tab-name' > <FormattedMessage id={tabName} /> </div>
    </div>
}

export default DashboardTabName;