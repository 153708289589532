import { FormattedMessage } from "react-intl";
import iconRegister from '../../../assets/images/icon-register.png';
import iconShare from '../../../assets/images/icon-share.png';
import iconReceive from '../../../assets/images/icon-receive.png';


const Earn = () => {

    return (
        <div className="home-earn">
            <div className="home-earn-title"><FormattedMessage id='EARN_TITLE' /></div>
            <div className="home-earn-content"><FormattedMessage id='EARN_CONTENT' /></div>
            <div className="home-earn-card-container">
                <div className="home-earn-card">
                    <div className="earn-card-img"><img alt="register" src={iconRegister} /></div>
                    <div className="earn-card-title"><FormattedMessage id='EARN_REGISTER_TITLE' /></div>
                    <div className="earn-card-content"><FormattedMessage id='EARN_REGISTER_CONTENT' /></div>
                </div>
                <div className="home-earn-card">
                    <div className="earn-card-img"><img alt="register" src={iconShare} /></div>
                    <div className="earn-card-title"><FormattedMessage id='EARN_SHARE_TITLE' /></div>
                    <div className="earn-card-content"><FormattedMessage id='EARN_SHARE_CONTENT' /></div>
                </div>
                <div className="home-earn-card">
                    <div className="earn-card-img"><img alt="register" src={iconReceive} /></div>
                    <div className="earn-card-title"><FormattedMessage id='EARN_RECEIVE_TITLE' /></div>
                    <div className="earn-card-content"><FormattedMessage id='EARN_RECEIVE_CONTENT' /></div>
                </div>
            </div>
        </div>
    )
}

export default Earn;