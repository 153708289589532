import { Button, Dropdown, Form } from 'antd';
import '../../Dashboard.less';
import { FormattedMessage, useIntl } from 'react-intl';
import LangSg from '../../../../assets/images/language-singapore.png';
import LangTh from '../../../../assets/images/language-thailand.png';
import LangPh from '../../../../assets/images/language-philippines.png';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { SET_CURRENCY, SET_LANGUAGE, useLayout } from '../../../../hooks/layout/LayoutContext';
import { CLEAR_CURRENT_USER, SET_DEFAULT_CURRENCY, SET_LOGIN_STATUS, useAuth } from '../../../../hooks/auth/AuthContext';
import useNotification from '../../../../hooks/layout/useNotification';
import { LOGOUT } from '../../../auth/auth.gql';
import { useCookies } from 'react-cookie';
import { clearAllTokens } from '../../../../shared/helpers/set-token-cookie.helper';
import HeaderWalletDropdown from './HeaderWalletDropdown';
import HeaderLanguageDropdown from './HeaderLanguageDropdown';
import './DashboardHeader.less';

interface Props {
    setActiveTabKey: any
}

const DashboardHeader = ({ setActiveTabKey }: Props) => {
    const { layoutState, layoutDispatch } = useLayout();
    const [langIcon, setLangIcon] = useState(LangTh);
    const { authState, authDispatch } = useAuth();
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [cookies] = useCookies();

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }
    }, [])

    const onLogoutClick = () => {
        logout();
    }

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            localStorage.removeItem('dashboardTab')
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    const onUserNameCLick = () => {
        setActiveTabKey('6');
    }

    const onLanguageClick = (language: string, currency: string) => {
        localStorage.setItem('pfxl', language);
        localStorage.setItem('pfxc', currency);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        layoutDispatch({ type: SET_CURRENCY, payload: currency });
        authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });
    }

    // useEffect(() => {
    //     if (layoutState.currency === 'SGD') {
    //         setLangIcon(LangSg);
    //     } else if (layoutState.currency === 'PHP') {
    //         setLangIcon(LangPh);
    //     } else {
    //         setLangIcon(LangTh)
    //     }
    // }, [layoutState.currency]);

    useEffect(() => {
        if (authState.defaultCurrency) {
            console.log('auths', authState.defaultCurrency)
            setRegionFlag(authState.defaultCurrency);
        }
    }, [authState.defaultCurrency]);

    const setRegionFlag = (currency: string) => {
        if (currency.toUpperCase() === 'SGD') {
            setLangIcon(LangSg);
            localStorage.setItem('pfxc', currency);
        } else if (currency.toUpperCase() === 'PHP') {
            setLangIcon(LangPh)
            localStorage.setItem('pfxc', 'PHP');
        } else {
            setLangIcon(LangTh)
            localStorage.setItem('pfxc', 'THB');
        }
    }


    return (
        <>
            <div className='header-right-first-row'>
                <div className='header-welcome'><FormattedMessage id='HEADER_WELCOME' /></div>
                <div className='login-header-elements'>
                    <Dropdown overlay={<HeaderWalletDropdown />} className="dashboard-transfer-wallet-dropdown" trigger={['click']}>
                        <div className='login-wallet-btn'>
                            <div className='wallet-icon-container'><div className='wallet-icon' /></div>
                            <div className='login-wallet-btn-amt'>
                                <div className='login-wallet-label'><FormattedMessage id='HEADER_BALANCE' /></div>
                                {authState.userProfile?.currency}
                                <NumberFormat displayType="text" value={authState?.totalBalance ? authState?.totalBalance.toFixed(2) : totalBalance.toFixed(2)} thousandSeparator={true} /></div>
                            {/* <AiOutlinePlusCircle /> */}
                        </div>
                    </Dropdown>
                    <div className="user-container" onClick={onUserNameCLick}>
                        <div className='login-user-icon'>
                            <div className='profile-icon' />
                        </div>
                        {/* <div className='login-username'>
                            {
                                authState.userProfile?.userName ?? ''
                            }
                        </div> */}
                    </div>
                    <Button className="logout-btn" type="default" htmlType="button" onClick={onLogoutClick} loading={logoutLoading}>
                        <div className='signout-icon' />
                    </Button>

                    <Dropdown overlay={<HeaderLanguageDropdown containerName={'language-dropdown'} onLanguageClick={onLanguageClick} />} className="header-language-dropdown" trigger={['click']}>
                        <div className='header-language-container'>
                            <img className="header-language-img" src={langIcon} alt="language" />
                            <DownOutlined />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </>
    )
}

export default DashboardHeader;