import { Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import '../../Dashboard.less';
import './DashboardTopInnerMenu.less';

const { TabPane } = Tabs;

interface Props {
    elementList: any[];
    needTranslation?: boolean;
}

const DashboardTopInnerMenu = ({ elementList, needTranslation=true }: Props) => {

    return (
        <div className="dashboard-top-inner-menu-container">
            <Tabs
                destroyInactiveTabPane
                tabPosition={'top'}>
                {
                    elementList.map((child) => {
                        return (
                            <TabPane tab={needTranslation ? <FormattedMessage id={child.tabName} /> : child.tabName} key={child.key}>
                                {child.element}
                            </TabPane>
                        );
                    })
                }
            </Tabs>
        </div>
    )
}

export default DashboardTopInnerMenu;