import { Dispatch, SetStateAction } from 'react';
import { Table, Pagination } from 'antd';
import './ItemList.less';
interface Props {
  columns: [];
  data: [];
  total: number;
  setSelectedItem?: Dispatch<SetStateAction<{}>>;
  setPageNo: Dispatch<SetStateAction<number>>;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  pageNo: number;
  pageSize?: number;
  pagination?: boolean;
  loading?: boolean
}

const ItemListPagination = ({ pagination = true, columns, data, total, setSelectedItem, setPageNo, setVisible, pageNo, pageSize = 10, loading }: Props) => {

  const onPageChange = (event: any) => {
    setPageNo(event);
  }
  return (
    <>
      <Table columns={columns} dataSource={data}
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
        className="table-container"
        pagination={false}
        loading={!loading ? false : true}
      />
      {
        pagination &&
        <Pagination className="pagination-position" pageSize={pageSize} current={pageNo} onChange={onPageChange} total={total} showSizeChanger={false} />
      }
    </>
  )
};

export default ItemListPagination;