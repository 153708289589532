import moment from 'moment';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { FormattedMessage, useIntl } from 'react-intl';
import './App.less';
import './Maintenance.less';

import maintenanceImg from './assets/images/under-maintenance.svg';
import logo from './assets/images/logo.svg';

const Maintenance = () => {
    const intl = useIntl();
    const date = moment(new Date()).format('dddd Do MMMM YYYY');
    const duration = '2 hours';

    const formatCountdownRender = ({ hours, minutes, seconds }: CountdownTimeDelta) => {
        let tensHour = Math.floor(hours / 10);
        let digitsHour = hours % 10;
        let tensMinutes = Math.floor(minutes / 10);
        let digitsMinutes = minutes % 10;
        let tensSeconds = Math.floor(seconds / 10);
        let digitsSeconds = seconds % 10;

        return <div className='maintenance-countdown-container'>
            <div className='maintenance-countdown-item-container'>
                <div className='maintenance-countdown-digit-container'>
                    <div className='maintenance-countdown-digits'><span>{tensHour}</span></div>
                    <div className='maintenance-countdown-digits'><span>{digitsHour}</span></div>
                </div>
                <div className='maintenance-countdown-digit-desc'>
                    <FormattedMessage id='MAINTENANCE_HOUR' />
                </div>
            </div>
            <div className='maintenance-countdown-divider'>:</div>
            <div className='maintenance-countdown-item-container'>
                <div className='maintenance-countdown-digit-container'>
                    <div className='maintenance-countdown-digits'><span>{tensMinutes}</span></div>
                    <div className='maintenance-countdown-digits'><span>{digitsMinutes}</span></div>
                </div>
                <div className='maintenance-countdown-digit-desc'>
                    <FormattedMessage id='MAINTENANCE_MINUTE' />
                </div>
            </div>
            <div className='maintenance-countdown-divider'>:</div>
            <div className='maintenance-countdown-item-container'>
                <div className='maintenance-countdown-digit-container'>
                    <div className='maintenance-countdown-digits'><span>{tensSeconds}</span></div>
                    <div className='maintenance-countdown-digits'><span>{digitsSeconds}</span></div>
                </div>
                <div className='maintenance-countdown-digit-desc'>
                    <FormattedMessage id='MAINTENANCE_SECOND' />
                </div>
            </div>
        </div>
    }

    return (
        <div className="site-maintenance-container">
            <div className='maintenance-logo'>
                <img src={logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} />
            </div>
            <div className='maintenance-content'>
                <div className='maintenance-img'>
                    <img src={maintenanceImg} alt={'maintenance'} />
                </div>
                <div className='maintenance-detail'>
                    <div className='maintenance-detail-title'><FormattedMessage id='MAINTENANCE_TITLE' /></div>
                    <div className='maintenance-detail-date'><FormattedMessage id='MAINTENANCE_DATE' values={{
                        0: date
                    }} /></div>
                    <div className='maintenance-detail-duration'><FormattedMessage id='MAINTENANCE_DURATION' values={{
                        0: duration
                    }} /></div>
                    <Countdown
                        date={Date.now() + 10000000000}
                        renderer={formatCountdownRender} />
                    <div className='maintenance-detail-desc-title'><FormattedMessage id='MAINTENANCE_DETAIL_TITLE' /></div>
                    <div className='maintenance-detail-desc-content'>        <FormattedMessage id={'MAINTENANCE_DETAIL'} values={{
                        0: <a href={`www.google.com`}
                            target="_blank" rel="noopener noreferrer"><FormattedMessage id={'MAINTENANCE_LIVE_CHAT'} /></a>,
                    }} /></div>
                </div>
            </div>
        </div>
    );
}

export default Maintenance;