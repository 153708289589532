
import { useQuery } from '@apollo/client';
import { Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import "../../Dashboard.less";
import "./WinLossDetail.less";
import NumberFormat from 'react-number-format';
import _, { capitalize } from 'lodash';
import ItemListPagination from '../../../../shared/components/item-list/ItemListPagination';
import useSpinner from '../../../../hooks/layout/useSpinner';
import moment from 'moment';
import { client } from '../../../../apollo-client';
import { endOfDay, format, startOfDay } from "date-fns";
import WinLossDetailFilter from './WinLossDetailFilter';
import { GET_TOTAL_SUMMARY, GET_WIN_LOSS_REPORT_DETAILS } from './WinLossDetail.gql';
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import TableStatisticContainer from '../../../../shared/components/table-statistic-container/TableStatisticContainer';

const WinLossDetail = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const [pageNo, setPageNo] = useState<number>(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE ? +process.env.REACT_APP_PAGE_SIZE : 10;
    const [total, setTotal] = useState<number>(0);
    const [reportData, setReportData] = useState<any>();
    const [filters, setFilters] = useState({
        userName: '', product: '', gameProvider: '', gameCode: '', status: '', roundId: '',
        startDate: startOfDay(new Date(moment().subtract(2, "days").toString())), endDate: endOfDay(new Date()),
    });
    const { data, loading, refetch } = useQuery(GET_WIN_LOSS_REPORT_DETAILS, {
        variables: { pageNo, pageSize, winLossReportDetailInput: filters },
        client, fetchPolicy: 'cache-and-network'
    });
    const { data: totalSummaryData, loading: totalSummaryLoading, refetch: totalWinLossRefetch } = useQuery(GET_TOTAL_SUMMARY, {
        variables: { winLossReportDetailInput: filters },
        client, fetchPolicy: 'cache-and-network'
    });
    const [totalBetAmount, setTotalBetAmount] = useState(0.00);
    const [totalWinLoss, setTotalWinLoss] = useState(0.00);
    const [totalValidBetAmount, setTotalValidBetAmount] = useState(0.00);
    const [totalValidWinLoss, setTotalValidWinLoss] = useState(0.00);
    const [tableStatisticList, setTableStatisticList] = useState<any>([]);

    useEffect(() => {
        refetch();
        totalWinLossRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        setLoading(loading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (data) {
            const count = data.winLossReportDetails && data.winLossReportDetails.count ? data.winLossReportDetails.count : 0;
            const _datas = data.winLossReportDetails && data.winLossReportDetails.winLossReports && data.winLossReportDetails.winLossReports.length > 0 ?
                data.winLossReportDetails.winLossReports.map((val: any) => {
                    return {
                        ...val,
                        key: val.id
                    };
                }) : [];
            setReportData(_datas);
            setTotal(count);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (totalSummaryData) {
            let totalWinLoss = totalSummaryData.winLossReportTotalSummary && totalSummaryData.winLossReportTotalSummary.totalWinLossAmount ?
                totalSummaryData.winLossReportTotalSummary.totalWinLossAmount : 0.00;
            let totalBetAmt = totalSummaryData.winLossReportTotalSummary && totalSummaryData.winLossReportTotalSummary.totalBetAmount ?
                totalSummaryData.winLossReportTotalSummary.totalBetAmount : 0.0;
            let totalValidBetAmt = totalSummaryData.winLossReportTotalSummary && totalSummaryData.winLossReportTotalSummary.totalValidBetAmount ?
                totalSummaryData.winLossReportTotalSummary.totalValidBetAmount : 0.0;
            let totalValidWinLoss = totalSummaryData.winLossReportTotalSummary && totalSummaryData.winLossReportTotalSummary.totalValidWinLossAmount ?
                totalSummaryData.winLossReportTotalSummary.totalValidWinLossAmount : 0.0

            setTotalWinLoss(totalWinLoss);
            setTotalBetAmount(totalBetAmt);
            setTotalValidBetAmount(totalValidBetAmt);
            setTotalValidWinLoss(totalValidWinLoss);

            setTableStatisticList([
                {
                    key: 'DASHBOARD_WINLOSS_TOTAL_BET',
                    value: totalBetAmt   
                },
                {
                    key: 'DASHBOARD_WINLOSS_TOTAL_VALID_BET',
                    value: totalValidBetAmt   
                },
                {
                    key: 'DASHBOARD_WINLOSS_TOTAL_VALID_WINLOSS',
                    value: totalValidWinLoss   
                }
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalSummaryData]);


    const columns: any = [
        {
            title: 'Member ID',
            dataIndex: 'userName',
            render: (text: any, record: any) => {
                return (
                    <Space size="middle">
                        {text ?? ''}
                    </Space>
                )
            }
        }, {
            title: 'Game Provider',
            dataIndex: 'gameProvider',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '-'}
                    </Space>
                )
            }
        }, {
            title: 'Product',
            dataIndex: 'product',
            render: (text: any) => {
                let jsonItem = JSON.parse(text);
                return (<Space size="middle">
                    {jsonItem["en"] || '-'}
                </Space>);
            }
        }, {
            title: 'Game Detail',
            dataIndex: 'gameType',
            render: (text: any, record: any) => {
                return (
                    <div>
                        <div><b>Type: </b>{text ?? '-'}</div>
                        <div><b>Name: </b> {JSON.parse(record?.gameName)["en"] || '-'}</div>
                    </div >
                )
            }
        },
        {
            title: 'Game Code',
            dataIndex: 'gameCode',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {capitalize(text) ?? '-'}
                    </Space>
                )
            }
        }, {
            title: 'Bet Date',
            dataIndex: 'gameBetDate',
            render: (text: any) => {
                const date = text ? format(new Date(text), "yyyy-MM-dd h:mm:ssa") : '-';
                return (
                    <Space size="middle">
                        {date}
                    </Space>
                )
            }
        }, {
            title: 'End Date',
            dataIndex: 'gameEndDate',
            render: (text: any) => {
                const date = text ? format(new Date(text), "yyyy-MM-dd h:mm:ssa") : '-';
                return (
                    <Space size="middle">
                        {date}
                    </Space>
                )
            }
        }, {
            title: 'Round ID',
            dataIndex: 'roundId',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '-'}
                    </Space>
                )
            }
        }, {
            title: 'Bet',
            dataIndex: 'betAmount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text != null ?
                                <NumberFormat value={(+text).toFixed(2)} displayType={'text'} thousandSeparator={true} /> :
                                '-'
                        }
                    </Space>
                )
            }
        }, {
            title: 'Win',
            dataIndex: 'winAmount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text != null ?
                                <NumberFormat value={(+text).toFixed(2)} displayType={'text'} thousandSeparator={true} /> :
                                '-'
                        }
                    </Space>
                )
            }
        }, {
            title: 'W/L',
            dataIndex: 'winLossAmount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text != null ?
                                <NumberFormat value={(+text).toFixed(2)} displayType={'text'} thousandSeparator={true} /> :
                                '-'
                        }
                    </Space>
                )
            }
        }, {
            title: 'Valid Bet',
            dataIndex: 'validBetAmount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text != null ?
                                <NumberFormat value={(+text).toFixed(2)} displayType={'text'} thousandSeparator={true} /> :
                                '-'
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Valid W/L',
            dataIndex: 'validWinLossAmount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text != null ?
                                <NumberFormat value={(+text).toFixed(2)} displayType={'text'} thousandSeparator={true} /> :
                                '-'
                        }
                    </Space>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ? capitalize(text) : '-'}
                    </Space>
                )
            }
        },
    ];


    return (
        <div className="winloss-detail-container">
            <DashboardMenuTitle text={'DASHBOARD_WINLOSS_DETAIL'} />
            <WinLossDetailFilter setFilters={setFilters} setPageNo={setPageNo} filters={filters} />
            <TableStatisticContainer statisticList={tableStatisticList} isLoading={totalSummaryLoading} />
            <ItemListPagination pagination={true} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} columns={columns} data={reportData || []}
                total={total} />
        </div>
    )
}

export default WinLossDetail;