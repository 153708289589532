
import { FormattedMessage, useIntl } from 'react-intl';
import '../../Dashboard.less';
import './DashboardProfile.less';
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import { BiEdit } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import DashboardProfileEdit from './DashboardProfileEdit';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROFILE, UPDATE_PROFILE } from './DashboardProfile.gql';
import useSpinner from '../../../../hooks/layout/useSpinner';
import moment from 'moment';

import { forEach } from 'lodash';
import useNotification from '../../../../hooks/layout/useNotification';
import { UPDATE_CURRENT_USER_PROFILE, useAuth } from '../../../../hooks/auth/AuthContext';

const DashboardProfile = () => {
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const { authState, authDispatch } = useAuth();
    const intl = useIntl();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const { data: profileData, loading: profileLoading, refetch } = useQuery(GET_PROFILE,
        { fetchPolicy: "no-cache" });

    const [updateProfile, { data: updateProfileData, loading: updateProfileLoading, error: updateProfileError }]
        = useMutation(UPDATE_PROFILE,
            { errorPolicy: "all" });

    useEffect(() => {
        if (profileLoading || updateProfileLoading)
            setLoading(true);
        else
            setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileLoading || updateProfileLoading]);

    useEffect(() => {
        if (updateProfileData && updateProfileData.updateProfile && updateProfileData.updateProfile.status) {
            if (updateProfileData.updateProfile.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_PROFILE_EDIT_DOB_SUCCESS' }));
                refetch();
                setEditModalVisible(false);

                if (authState && authState.userProfile) {
                    let currentProfile = authState.userProfile;
                    currentProfile.dobUpdated = true;

                    authDispatch({ type: UPDATE_CURRENT_USER_PROFILE, payload: currentProfile });
                }
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_${updateProfileData.updateProfile.status}` }));
            }
        }
    }, [updateProfileData])

    useEffect(() => {
        let profileItems: any;
        let bankAccs: any = [];
        if (profileData && profileData.myProfile) {
            let profile = profileData.myProfile;
            profileItems =
                [
                    {
                        list: 'profile1',
                        items: [
                            {
                                name: 'email',
                                value: profile.email ?? '-',
                                getFromStringFile: false
                            },
                            {
                                name: 'username',
                                value: profile.userName ?? '-',
                                getFromStringFile: false
                            },
                            {
                                name: 'dob',
                                value: profile.dob ? moment(profile.dob).format("YYYY-MM-DD") : '-',
                                getFromStringFile: false
                            },
                            {
                                name: 'more',
                                value: '',
                                getFromStringFile: false
                            }
                        ],
                    },
                    {
                        list: 'profile2',
                        items: [
                            {
                                name: 'contact',
                                value: profile.phone ?? '-',
                                getFromStringFile: false
                            },
                            {
                                name: 'name',
                                value: profile.name ?? '-',
                                getFromStringFile: false
                            },
                            {
                                name: 'currency',
                                value: `CURRENCY_${profile.currency.toUpperCase()}`,
                                getFromStringFile: true
                            },
                            {
                                name: 'bankcountry',
                                value: `COUNTRIES_${profile.countryOfBank.toUpperCase()}`,
                                getFromStringFile: true
                            }
                        ],
                    }
                ];

            if (profile.userBankAccounts && profile.userBankAccounts.length > 0) {
                forEach(profile.userBankAccounts, (obj) => {
                    bankAccs.push({
                        id: obj.id,
                        accName: obj.bankAccountName,
                        accNum: obj.bankAccountNumber,
                        imgUrl: obj.imageUrl,
                    })
                })

            }

            setBankList(bankAccs);
            setProfileList(profileItems);

        }
    }, [profileData])

    const onEditProfileClick = () => {
        setEditModalVisible(true);
    }

    const onMoreInfoClick = () => {
        navigate('/home');
    }

    const renderProfileItems = (child: any[]) => {
        return child.map((item) => {
            return item.name === 'more' ? authState.userProfile?.dobUpdated === false ?
                <div key={item.name} className='dashboard-profile-card-buttons'>
                    <div className='dashboard-profile-card-buttons-edit' onClick={onEditProfileClick}><BiEdit /></div>
                    {/* <div className='dashboard-profile-card-buttons-more' onClick={onMoreInfoClick}><FormattedMessage id='DASHBOARD_PROFILE_MORE' /></div> */}
                </div> : <div key={''}></div> :
                <div key={item.name} className='dashboard-profile-card-item'>
                    <div className='dashboard-profile-card-item-title'><FormattedMessage id={`DASHBOARD_PROFILE_${item.name.toUpperCase()}`} /></div>
                    <div className='dashboard-profile-card-item-content'>
                        {item.getFromStringFile ?
                            <FormattedMessage id={item.value} /> : item.value
                        }
                    </div>
                </div>
        })
    }

    return (
        <>
            <div className="dashboard-profile-container">
                <DashboardMenuTitle text={'DASHBOARD_PROFILE_TITLE'} />
                <div className='dashboard-profile-card-container'>
                    {profileList.map((profile: any) => {
                        return <div key={profile.list} className='dashboard-profile-card'>
                            {profile.items && renderProfileItems(profile.items)}
                        </div>
                    })}
                    <div className='dashboard-profile-card'>
                        <div className='dashboard-profile-card-item'>
                            <div className='dashboard-profile-card-item-title'><FormattedMessage id='DASHBOARD_PROFILE_BANKDETAIL' /></div>
                            <div className='dashboard-profile-card-item-content'>
                                {bankList.map((item: any) => {
                                    return <div key={item.accName} className='dashboard-profile-card-bank-acc-container'>
                                        <div className='dashboard-profile-card-bank-acc-icon'>
                                            <img src={item.imgUrl} alt="bank account" />
                                        </div>
                                        <div className='dashboard-profile-card-bank-acc-content'>
                                            <div className='dashboard-profile-card-bank-acc-num'>
                                                {item.accName}
                                            </div>
                                            <div className='dashboard-profile-card-bank-acc-name'>
                                                {item.accNum}
                                            </div>
                                        </div>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <DashboardProfileEdit updateProfile={updateProfile} modalVisible={{ visible: editModalVisible, setVisible: setEditModalVisible }} />
            </div>
        </>
    )
}

export default DashboardProfile;