
import "./Auth.less";
import { Steps, Row, Col } from "antd";
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAuth } from '../../hooks/auth/AuthContext';
import { GET_USER_PROFILE } from '../../shared/gql/profile.gql';
import useSpinner from '../../hooks/layout/useSpinner';
import useNotification from '../../hooks/layout/useNotification';
import { useIntl } from 'react-intl';
import RegisterStep1 from "./register-components/RegisterStep1";

import bannerImg from '../../assets/images/register-banner.jpg';
import bannerImgTh from '../../assets/images/register-banner-th.jpg';
import RegisterStep2 from "./register-components/RegisterStep2";
import RegisterStep3 from "./register-components/RegisterStep3";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../../hooks/layout/LayoutContext";
import RegisterMobileStep1 from "./mobile-register-components/RegisterStep1";
import RegisterMobileStep2 from "./mobile-register-components/RegisterStep2";
import RegisterMobileStep3 from "./mobile-register-components/RegisterStep3";

const { Step } = Steps;

const Register = () => {
    const { innerWidth: width } = window;
    const { authState, authDispatch } = useAuth();
    const { setLoading } = useSpinner();
    const intl = useIntl();
    const [getProfile, { data: getData }] = useLazyQuery(GET_USER_PROFILE, { fetchPolicy: "cache-and-network" });
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();
    const { layoutState } = useLayout();

    useEffect(() => {
        if (authState && authState.isLogin) {
            navigate('/home/dashboard');
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const next = () => {
        setCurrentStep(currentStep + 1);
    };

    const backToFirstPage = () => {
        setCurrentStep(0);
    }

    const steps = [
        {
            id: '1',
            content: <RegisterStep1 next={next} step={currentStep + 1} />,
        },
        {
            id: '2',
            content: <RegisterStep2 next={next} step={currentStep + 1} />,
        },
        {
            id: '3',
            content: <RegisterStep3 backToFirstPage={backToFirstPage} />,
        },
    ];

    const mobileSteps = [
        {
            id: '1',
            content: <RegisterMobileStep1 next={next} step={currentStep + 1} />
        },
        {
            id: '2',
            content: <RegisterMobileStep2 next={next} step={currentStep + 1} />
        },
        {
            id: '3',
            content: <RegisterMobileStep3 backToFirstPage={backToFirstPage} />
        },
    ];

    return (

        width > 820 ?

            <div className="register-container">
                <div className="register-wrapper">
                    <Row className="register-form-container">
                        <Col span={12} className="register-form-left-element">
                            <img src={layoutState.locale === 'th' ? bannerImgTh : bannerImg} alt="register banner" className="reg-img" />
                        </Col>
                        <Col span={12} className="register-form-right-element">
                            <Steps
                                direction={width > 576 ? "horizontal" : "vertical"}
                                current={currentStep}
                                size="default"
                            >
                                {steps.map((item) => (
                                    <Step key={item.id} />
                                ))}
                            </Steps>
                            <div className="register-form-items">{steps[currentStep].content}</div>
                        </Col>
                    </Row>
                </div>
            </div> :
            <div className="m-register-container">
                <div className="m-register-wrapper">
                    <Row className="m-register-form-container">
                        <Col span={12} className="m-register-form-top-element">
                            <img src={layoutState.locale === 'th' ? bannerImgTh : bannerImg} alt="banner" className="m-reg-img" />
                        </Col>
                        <Col span={24} className="m-register-form-bottom-element">
                            <Steps
                                direction={"horizontal"}
                                current={currentStep}
                                size="default"
                            >
                                {mobileSteps.map((step: any) => (
                                    <Step key={step.id} />
                                ))}
                            </Steps>
                            <div className="m-register-form-items">{mobileSteps[currentStep].content}</div>
                        </Col>
                    </Row>
                </div>
            </div>
    );
};

export default Register;
