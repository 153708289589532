import { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import GeneralFooter from "./shared/components/footer/GeneralFooter";
import GeneralHeader from "./shared/components/header/GeneralHeader";
import Home from './pages/home/Home';
import { Layout, Spin, notification, Modal } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_DEFAULT_CURRENCY, SET_LOGIN_STATUS, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, SET_CURRENCY, SET_LANGUAGE, useLayout } from './hooks/layout/LayoutContext';
import { routes, RouteInfo } from './routes';
import { useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_PROFILE } from './shared/gql/profile.gql';

import Register from './pages/auth/Register';
import { getDatabase, ref, off, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { LOGOUT } from './pages/auth/auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookie.helper';
import useSpinner from './hooks/layout/useSpinner';
import Dashboard from './pages/dashboard/Dashboard';
import { checkLocation } from './shared/helpers/general-helpers';


const cookies = new Cookies();
const { Content } = Layout;

const AppProtected = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || 'prod-aff-rt';
    const [listener, setListener] = useState<any>();
    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'no-cache',
        errorPolicy: "all",
        //notifyOnNetworkStatusChange: true
    });
    const [isLogin, setIsLogin] = useState(false);
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });

    useEffect(() => {
        if (logoutLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading])


    useEffect(() => {
        if (authState.isLogin) {
            setIsLogin(true);
            called && refetch ? refetch() : getUserProfile();
        } else {
            if (listener) {
                off(listener);
            }
            domainCurrency();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    //Set user profile is profile data changed
    useEffect(() => {
        if (data && !error) {
            authDispatch({ type: SET_CURRENT_USER, payload: data });
            localStorage.setItem('pfxc', data?.userProfile?.currency);
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: data?.userProfile?.currency });
            layoutDispatch({ type: SET_CURRENCY, payload: data?.userProfile?.currency });

            //firebase
            if (data?.userProfile && data?.userProfile?.id && authState.isLogin) {
                localStorage.setItem("ua", data?.userProfile?.ua);
                const firebaseConfig = {
                    databaseURL: "https://ib8web-default-rtdb.asia-southeast1.firebasedatabase.app",
                };
                const app = initializeApp(firebaseConfig);
                const db = getDatabase(app);
                const listener = ref(db, process.env.REACT_APP_ENV + data?.userProfile?.id);
                setListener(listener);
                onValue(listener, (snapshot) => {
                    const firebase = snapshot.val();
                    const newUa = localStorage.getItem("ua");
                    if (newUa && firebase.ua) {
                        if (firebase.ua.toString() != newUa) {
                            localStorage.removeItem("ua");
                            off(listener);
                            logout();
                        }
                    }
                });
            }
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    useEffect(() => {
        if (layoutState.showModal && layoutState.showModal.message) {
            switch (layoutState?.showModal.type) {
                case "success":
                    Modal.success({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "error":
                    Modal.error({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "warning":
                    Modal.warning({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "info":
                default:
                    Modal.info({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showModal])

    useEffect(() => {
        if (!cookies.get(refreshTokenKey) && authState.isLogin) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/auth', { replace: true })
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.get(refreshTokenKey)]);

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    const domainCurrency = async () => {
        const currencyFromStorage = localStorage.getItem('pfxc');
        if (currencyFromStorage && currencyFromStorage !== "undefined") {
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currencyFromStorage });
            layoutDispatch({ type: SET_CURRENCY, payload: currencyFromStorage });
        } else {
            let currency = 'THB';
            // if (window.location.hostname === 'localhost') {
            //     currency = 'SGD';
            // }

            const loc: string = await checkLocation();

            console.log('loc', loc)

            if (loc === "SG") {
                currency = 'SGD';
                setDefaultLanguage(currency);
            } else if (loc === "TH") {
                currency = 'THB';
                setDefaultLanguage(currency);
            } else if (loc === 'PH') {
                currency = 'PHP';
                setDefaultLanguage(currency);
            } else {
                if (window.location.hostname === 'aff.ib8sgd.co' || window.location.hostname === 'aff.ib8sgd.com'
                    || window.location.hostname === 'aff.ib8.site' || window.location.hostname === 'aff.ib8.group'
                    || window.location.hostname === 'aff.ib8.info' || window.location.hostname === 'aff.ib8sgd.net'
                    || window.location.hostname === 'aff.ib8.international' || window.location.hostname === 'aff.ib8.world') {
                    currency = 'SGD';
                } else if (window.location.hostname === 'aff.ib8ph.com' || window.location.hostname === 'aff.ib8ph.co'
                    || window.location.hostname === "aff.ib8ph.net" || window.location.hostname === "aff.ib8ph.bet"
                    || window.location.hostname === "aff.ib8ph.ph" || window.location.hostname === "aff.ib8ph.vip"
                    || window.location.hostname === "aff.ib8ph.live") {
                    currency = 'PHP';
                }
            }
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });
            layoutDispatch({ type: SET_CURRENCY, payload: currency });
            localStorage.setItem('pfxc', currency);
            setDefaultLanguage(currency);
        }
    };

    const setDefaultLanguage = (currency: string) => {
        if (currency === 'THB') {
            localStorage.setItem('pfxl', 'th');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'th' });
        } else if (currency === 'SGD') {
            localStorage.setItem('pfxl', 'en');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'en' });
        } else if (currency === 'PHP') {
            localStorage.setItem('pfxl', 'en');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'en' });
        }
    }

    const protectedRoutesAccessCheck = (route: RouteInfo) => {
        return (<Suspense fallback={<Home />}>
            <route.component routes={route.routes} />
        </Suspense>);
    }

    return (<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} spinning={layoutState.showLoading}>
        <Layout className="app-container">
            <Layout className="site-layout">
                {!authState?.isLogin ?
                    <>
                        <GeneralHeader />
                        <Content
                            className="site-layout-background">
                            <Routes>
                                <Route path='*' element={<Home />} />
                                <Route path="/register" element={<Register />} />
                            </Routes>
                            <GeneralFooter />
                        </Content>
                    </>
                    :
                    <>
                        <Content
                            className={authState?.isLogin ? "site-layout-background-login" : "site-layout-background"}>
                            <Routes>
                                <Route path='' element={<Dashboard />} />
                                {routes.map((route: RouteInfo, i) => {
                                    return (
                                        <Route key={i} path={`${route.path}`} element={
                                            protectedRoutesAccessCheck(route)
                                        } />
                                    );
                                })}
                            </Routes>
                        </Content>
                    </>
                }

            </Layout>
        </Layout>
    </Spin>);
}

export default AppProtected;