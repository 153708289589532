
import "./Dashboard.less";
import Logo from '../../assets/images/logo.svg';
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth/AuthContext";
import { Tabs } from "antd";
import DashboardTabName from "./components/DashboardTabName";
import { useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import DashboardHeader from "./components/header/DashboardHeader";
import DashboardHome from "./components/home/DashboardHome";
import MemberList from "./components/member-list/MemberList";
import DashboardWithdrawDetail from "./components/withdrawal-detail/DashboardWithdrawDetail";
import WinLossDetail from "./components/win-loss-detail/WinLossDetail";
import CommissionReport from "./components/commission-report/CommissionReport";
import DashboardProfile from "./components/profile/DashboardProfile";

const { TabPane } = Tabs;

const Dashboard = () => {
    const intl = useIntl();
    const { id } = useParams<{ id: string }>();
    const [activeTabKey, setActiveTabKey] = useState<any>(localStorage.getItem('dashboardTab') ?? '1');
    const [selectedType, setSelectedType] = useState('bettingSummary');
    const { authState } = useAuth();
    const url = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://' + window.location.hostname;
    const refLink = `${url}/home/referral/?refId=${authState?.userProfile?.referralCode ?? '-'}`;

    useEffect(() => {
        if (activeTabKey === 'deposit') {
            setActiveTabKey('4');
            setSelectedType('deposit');
        } else if (activeTabKey === 'withdraw') {
            setActiveTabKey('4');
            setSelectedType('withdraw');
        }
    }, [activeTabKey])

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
        localStorage.setItem("dashboardTab", key);
    }

    useEffect(() => {
        console.log("tab id:" + id);
        if (id) {
            setActiveTabKey(id);
        }
    }, [id])

    return (
        <div className="dashboard-container">
            <div className="dashboard-tab-menu-container">
                <DashboardHeader setActiveTabKey={setActiveTabKey} />
                <Tabs destroyInactiveTabPane activeKey={activeTabKey} tabPosition={'left'} onChange={onTabChange}>
                    <TabPane key="0" tab={
                        <img className='header-logo' src={Logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} />
                    } disabled />
                    <TabPane tab={<DashboardTabName tab={'home'} />} key="1">
                        <DashboardHome />
                    </TabPane>
                    <TabPane tab={<DashboardTabName tab={'member'} />} key="2">
                        <MemberList />
                    </TabPane>
                    <TabPane tab={<DashboardTabName tab={'winloss'} />} key="3">
                        <WinLossDetail />
                    </TabPane>
                    <TabPane tab={<DashboardTabName tab={'comm-report'} />} key="4">
                        <CommissionReport />
                    </TabPane>
                    <TabPane tab={<DashboardTabName tab={'withdraw'} />} key="5">
                        <DashboardWithdrawDetail setActiveTab={setActiveTabKey} />
                    </TabPane>
                    <TabPane tab={<DashboardTabName tab={'profile'} />} key="6">
                        <DashboardProfile />
                    </TabPane>
                    <TabPane className="tab-copyright" key="999" tab={
                        <FormattedMessage id='FOOTER_COPYRIGHT_CONTENT' values={{
                            year: new Date().getFullYear().toString()
                        }} />
                    } disabled />
                </Tabs>
            </div>
        </div>
    );
};

export default Dashboard;
