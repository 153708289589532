import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
	path: string;
	component: any;
	routes?: RouteInfo[];
	lazy?: boolean;
	protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
	const Component: any = React.lazy(importStatement);
	Component.preload = importStatement;
	return Component;
};

const Register = ReactLazyPreload(() => import("./pages/auth/Register"));
Register.preload();

const MenuProduct = ReactLazyPreload(()=> import("./pages/header-menu-pages/product/Product"))
MenuProduct.preload();

const MenuContactUs = ReactLazyPreload(()=> import("./pages/header-menu-pages/contact-us/ContactUs"))
MenuContactUs.preload();

const MenuTNC = ReactLazyPreload(()=>import("./pages/header-menu-pages/tnc/TNC"));
MenuTNC.preload();

const CommPlan = ReactLazyPreload(()=>import("./pages/header-menu-pages/comm-plan/CommPlan"));
CommPlan.preload();

const FAQ = ReactLazyPreload(()=>import("./pages/header-menu-pages/faq/FAQ"));
FAQ.preload();

const Dashboard = ReactLazyPreload(()=>import("./pages/dashboard/Dashboard"));
Dashboard.preload();

export const routes: RouteInfo[] = [
	{
		path: "/register",
		component: Register,
		protected: true,
	},
	{
		path: "/product",
		component: MenuProduct,
		protected: true
	},
	{
		path: "/contact-us",
		component: MenuContactUs,
		protected: true
	},
	{
		path: "/terms",
		component: MenuTNC,
		protected: true
	},
	{
		path: "/commission-plans",
		component: CommPlan,
		protected: true
	},
	{
		path: "/faq",
		component: FAQ,
		protected: true
	},
	{
		path: "/dashboard/:id",
		component: Dashboard,
		protected: true
	},
	{
		path: "*",
		component: NoMatch,
	},
];