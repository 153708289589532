
import "../Auth.less";
import { FormattedMessage } from "react-intl";

const RegisterDisclaimer = () => {
    return (
        <>
            <div className="register-tnc">
                <FormattedMessage id='AUTH_REGISTER_TNC' values={{
                    0: <span className="tnc-bold"><FormattedMessage id='AUTH_REGISTER_TNC_BTN_TXT' /></span>
                }} />
            </div>
        </>
    );
};

export default RegisterDisclaimer;
