
import "./Home.less";
import { useQuery } from '@apollo/client';
import Banner from "./home-components/Banner";
import { GET_BANNER } from "./Home.gql";
import banner1 from '../../assets/images/homebanner-01.jpg';
import banner2 from '../../assets/images/homebanner-02.jpg';
import Earn from "./home-components/Earn";
import Commission from "./home-components/Commission";
import Benefit from "./home-components/Benefit";
import Products from "./home-components/Products";
import BottomBanner from "./home-components/BottomBanner";
import { setDBLanguageToGet } from "../../shared/helpers/language.helpers";
import { useLayout } from "../../hooks/layout/LayoutContext";

const Home = () => {
    //const { data: bannerData } = useQuery(GET_BANNER, { fetchPolicy: 'cache-and-network' });
    const { layoutState } = useLayout();

    const bannerData = {
        banners: [
            {
                id: '1',
                title: 'banner1',
                pictureUrl: banner1,
                bannerTxt: setDBLanguageToGet(layoutState.locale) === 'th' ?
                    'ค่าคอมมิชชั่นตลอดชีพสูงถึง 40% ของส่วนแบ่งรายได้' : 'LIFETIME COMMISSION UP TO 40% REVENUE SHARE'

            },
            {
                id: '2',
                title: 'banner2',
                pictureUrl: banner2,
                bannerTxt: setDBLanguageToGet(layoutState.locale) === 'th' ?
                    'โดยเฉลี่ยแล้วพันธมิตรของเรารับรายได้ $3,318/เดือน' : 'ON AVERAGE OUR PARTNER EARNS $3,318/MONTH'
            },
        ]
    }

    return (
        <div className="home-container">
            <Banner bannerData={bannerData} />
            <Earn />
            <Commission />
            <Benefit />
            <Products />
            <BottomBanner />
        </div>
    );
};

export default Home;
