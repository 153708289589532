import { FormattedMessage } from "react-intl";
import iconRegister from '../../../assets/images/icon-register.png';
import benefit1 from '../../../assets/images/why-1.jpg';
import benefit2 from '../../../assets/images/why-2.jpg';
import benefit3 from '../../../assets/images/why-3.jpg';
import benefit4 from '../../../assets/images/why-4.jpg';
import benefit5 from '../../../assets/images/why-5.jpg';
import benefit6 from '../../../assets/images/why-6.jpg';

const Benefit = () => {

    return (
        <div className="home-benefit">
            <div className="home-benefit-title"><FormattedMessage id='BENEFIT_TITLE' /></div>
            <div className="home-benefit-content"><FormattedMessage id='BENEFIT_CONTENT' /></div>
            <div className="home-benefit-card-container">
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit1} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_1' /></div>
                    </div>
                </div>
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit2} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_2' /></div>
                    </div>
                </div>
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit3} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_3' /></div>
                    </div>
                </div>
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit4} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_4' /></div>
                    </div>
                </div>
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit5} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_5' /></div>
                    </div>
                </div>
                <div className="home-benefit-card">
                    <div className="benefit-card-img"><img alt="register" src={benefit6} /></div>
                    <div className="benefit-card-title">
                        <div className="benefit-card-title-txt"><FormattedMessage id='BENEFIT_CARD_6' /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefit;