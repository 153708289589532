import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";


const BottomBanner = () => {
    const navigate = useNavigate();
    
    const onRegClick = () => {
        navigate('/home/register');
    }
    
    return (
        <div className="home-bottom">
            <div className="home-bottom-title"><FormattedMessage id='WAIT_TITLE'/></div>
            <div className="home-bottom-content"><FormattedMessage id='WAIT_CONTENT'/></div>
            <div className="home-bottom-btn" onClick={onRegClick}><FormattedMessage id='BTN_START_EARN'/></div>
        </div>
    )
}

export default BottomBanner;