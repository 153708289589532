
import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import "../../Dashboard.less";
import NumberFormat from 'react-number-format';
import _, { capitalize } from 'lodash';
import ItemListPagination from '../../../../shared/components/item-list/ItemListPagination';
import useSpinner from '../../../../hooks/layout/useSpinner';
import moment from 'moment';
import { COMM_REPORT } from './CommissionReport.gql';
import { client } from '../../../../apollo-client';
import { endOfDay, format, startOfDay, subMonths, startOfMonth } from "date-fns";
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import { setDBLanguageToGet } from '../../../../shared/helpers/language.helpers';
import { useLayout } from '../../../../hooks/layout/LayoutContext';
import TablePageFilter from '../../../../shared/components/table-page-filter/TablePageFilter';

const CommissionReport = () => {
    const intl = useIntl();
    const { layoutState } = useLayout();
    const { setLoading } = useSpinner();
    const [pageNo, setPageNo] = useState<number>(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE ? +process.env.REACT_APP_PAGE_SIZE : 10;
    const [total, setTotal] = useState<number>(0);
    const [reportData, setReportData] = useState<any>();
    const [filters, setFilters] = useState({
        startDate: format(startOfDay(subMonths(startOfMonth(new Date()), 1)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        endDate: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    });
    const { data, loading, refetch } = useQuery(COMM_REPORT, {
        variables: { pageNo, pageSize, commissionReportFilterInput: filters },
        client, fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        setLoading(loading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (data && data.commissionReport) {
            console.log('data', data);
            setTotal(data?.commissionReport?.count ?? 0);

            if (data.commissionReport?.commissionReport && data.commissionReport?.commissionReport?.length > 0) {
                let reportData = data.commissionReport?.commissionReport.map((val: any) => {
                    return {
                        ...val,
                        key: val.id
                    };
                })

                setReportData(reportData);
            } else {
                setReportData([]);
            }
        }
    }, [data])

    const columns: any = [
        {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_YM' }),
            dataIndex: 'commDate',
            render: (text: any) => {
                const createdAt = text ? format(new Date(text), "yyyyMM") : '-';
                return (
                    <Space size="middle">
                        {createdAt}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_ID' }),
            dataIndex: 'aff',
            render: (aff: any) => {
                return (
                    <Space size="middle">
                        {aff && aff.userName ? aff?.userName : '-'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_REG_DATE' }),
            dataIndex: 'aff',
            render: (aff: any) => {
                const createdAt = aff && aff.createdAt ? format(new Date(aff.createdAt), "dd/MM/yyyy HH:mm") : '-';
                return (
                    <Space size="middle">
                        {createdAt}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_TOTALREG' }),
            dataIndex: 'totalRegistered',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '0'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_ACTIVE_PLAYER' }),
            dataIndex: 'activePlayer',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '0'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_TOTAL_WIN_LOSS' }),
            dataIndex: 'totalWinLoss',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_PLATFORM_FEE' }),
            dataIndex: 'platformFee',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_BONUS_FEE' }),
            dataIndex: 'bonusFee',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_REBATE_FEE' }),
            dataIndex: 'rebateFee',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_COMMRATE' }),
            dataIndex: 'commRate',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={(+parseFloat(text) * 100).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                    suffix={'%'}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_GCR_AMOUNT' }),
            dataIndex: 'grossCommission',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_AMOUNT' }),
            dataIndex: 'amount',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_CR_STATUS' }),
            dataIndex: 'status',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {capitalize(text) ?? '0'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_PAIDAT_AMOUNT' }),
            dataIndex: 'updatedAt',
            render: (text: any) => {
                const updatedAt = text ? format(new Date(text), "dd/MM/yyyy HH:mm") : '-';
                return (
                    <Space size="middle">
                        {updatedAt}
                    </Space>
                )
            }
        },
    ]

    return (
        <div className="member-list-container">
            <DashboardMenuTitle text={'DASHBOARD_MENU_COMM_REPORT'} />
            <TablePageFilter setFilters={setFilters} />
            <ItemListPagination pagination={true} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} columns={columns} data={reportData || []}
                total={total} />
        </div>
    )
}

export default CommissionReport;