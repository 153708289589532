
import "../AuthMobile.less";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from 'react';
import phoneIcon from '../../../assets/images/icon-form-contact.png';
import dobIcon from '../../../assets/images/icon-form-calendar.png';
import emailIcon from '../../../assets/images/icon-form-mail.png';
import userNameIcon from '../../../assets/images/icon-form-username.png';
import RegisterDisclaimer from "./RegisterMobileDisclaimer";
import PhoneInputFloatingLabel from "../../../shared/components/phone-input/Phone-Input-floating-label";
import moment from "moment";
import { SET_LOGIN_STATUS, useAuth } from "../../../hooks/auth/AuthContext";
import { useMutation, useQuery } from "@apollo/client";
import { setTokenCookie } from "../../../shared/helpers/set-token-cookie.helper";
import { SIGN_UP_COUNTRIES, USER_SIGNUP } from "../../auth/auth.gql";
import { GlobalOutlined } from "@ant-design/icons";

interface Props {
    step: number;
    next: () => void;
}

const RegisterMobileStep2 = ({ next, step }: Props) => {
    const [form2] = Form.useForm();
    const intl = useIntl();
    const [emailFocus, setEmailFocus] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [fullNameFocus, setFullNameFocus] = useState(false);
    const [dobFocus, setDobFocus] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [fullNameInput, setFullNameInput] = useState("");
    const [dobInput, setDobInput] = useState("");
    const [phone, setPhone] = useState<string>();
    const [dialCode, setDialCode] = useState('TH');
    const { authDispatch } = useAuth();
    const { data: countryData } = useQuery(SIGN_UP_COUNTRIES, { fetchPolicy: "no-cache" });
    const [countryInput, setCountryInput] = useState("");
    const [countryFocus, setCountryFocus] = useState(false);
    const [signUpCountryList, setSignUpCountryList] = useState([]);
    const [signUp, { data, loading, error }] = useMutation(USER_SIGNUP, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const urlAccName = process.env.REACT_APP_STORAGE_ACCOUNTNAME ? process.env.REACT_APP_STORAGE_ACCOUNTNAME : '';

    useEffect(() => {
        if (data && data.userSignUp) {
            if (data.userSignUp.status === 'SUCCESS') {
                // const { a, r, sid } = data.userSignUp;
                // setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || 'gmf-at', a);
                // setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || 'gmf-rt', r);
                // setTokenCookie('gmf-sid', sid);

                // authDispatch({ type: SET_LOGIN_STATUS, payload: true });
                next();
            } else {
                next();
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (countryData && countryData.signUpCountries) {
            if (countryData.signUpCountries) {
                const _datas = countryData.signUpCountries && countryData.signUpCountries.length > 0 ?
                    countryData.signUpCountries.map((val: any) => {
                        return {
                            ...val,
                            key: val.id
                        };
                    }) : [];
                setSignUpCountryList(_datas);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryData]);


    useEffect(() => {
        if (error) {
            next();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const onFinish = (fieldsValue: any) => {
        const regStorage = localStorage.getItem("regParam");
        const regStorageJson = regStorage ? JSON.parse(regStorage) : {};

        form2.setFields([
            {
                name: "dob",
                errors: [""],
            }
        ]);
        if (dobInput === "") {
            form2.setFields([
                {
                    name: "dob",
                    errors: [intl.formatMessage({ id: 'AUTH_REGISTER_FORM_DOB_REQUIRED' })],
                }
            ]);
            return;
        }

        const variables: any = {
            registerInput: {
                username: regStorageJson.username ?? '',
                password: regStorageJson.password ?? '',
                affiliate: regStorageJson.affiliate ?? '',
                email: fieldsValue.email ?? '',
                phoneNumber: phone ?? '',
                dob: new Date(moment(dobInput).startOf('day').toString()),
                fullName: fieldsValue.fullname ?? '',
                dialCode: dialCode,
                url: window.location.hostname,
                countryId: countryInput, 
            }
        };
        signUp({ variables });
    };

    const onValuesChange = ({ phone }: any) => {
        if (phone) {
            setPhone(phone);
        }
    }

    const emailLabelClass = emailFocus || (emailInput && emailInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const emailInputClass = emailFocus || (emailInput && emailInput.length !== 0) ? "m-input-underline" : "";

    const fullNameLabelClass = fullNameFocus || (fullNameInput && fullNameInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const fullNameInputClass = fullNameFocus || (fullNameInput && fullNameInput.length !== 0) ? "m-input-underline" : "";

    const dobLabelClass = dobFocus || (dobInput && dobInput.length !== 0) ? "m-label m-label-float m-dob-label" : "m-label m-dob-label";
    const dobInputClass = dobFocus || (dobInput && dobInput.length !== 0) ? "m-input-underline" : "";

    const countryLabelClass = countryFocus || (countryInput && countryInput.length !== 0) ? "m-label m-label-float m-dob-label" : "m-label m-dob-label";
    const countryInputClass = countryFocus || (countryInput && countryInput.length !== 0) ? "m-input-underline" : "";

    const onCountryCodeChange = (selectedValue: string, option: any) => {
        setCountryInput(selectedValue);
    }

    return (
        <>
            <div className="m-register-form-title"><FormattedMessage id='AUTH_REGISTER_STEP2' /></div>
            <Form
                form={form2}
                layout="vertical"
                onValuesChange={onValuesChange}
                onFinish={onFinish}
            >
                <div className="m-register-form-item">
                    <img src={emailIcon} alt="email" className="m-form-email-icon" />
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL_INVALID' }) }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setEmailFocus(false)}
                            onFocus={() => setEmailFocus(true)}
                        >
                            <Input className="m-input" value={emailInput} onChange={e => setEmailInput(e.target.value)} />
                            <label className={emailLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_EMAIL' })}</label>
                            <div className={emailInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={phoneIcon} alt="phone" className="m-form-password-icon" />
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                            { min: 3, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                            { min: 8, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) },
                            { max: 12, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) }]}>
                        <PhoneInputFloatingLabel phoneFocus={phoneFocus} setPhoneFocus={setPhoneFocus} setDialCode={setDialCode} />
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <GlobalOutlined className="globe-icon" />
                    <Form.Item
                        name="country">
                        <div
                            className="float-label"
                            onBlur={() => setCountryFocus(false)}
                            onFocus={() => setCountryFocus(true)}
                        >
                            <Select showSearch className="auth-country-selector" dropdownClassName="country-dropdown"
                                optionFilterProp="children" onChange={onCountryCodeChange}
                                optionLabelProp="displaytitle">
                                {
                                    signUpCountryList?.map((d: any) => {
                                        return (<Select.Option key={d.id} value={d.id}
                                            displaytitle={
                                                <div className="country-selected-title">
                                                    <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                                                    {`${d.name}`}
                                                </div>}
                                        >
                                            <div className="country-dropdown-item">
                                                <img src={`https://${urlAccName}.blob.core.windows.net/flags/${d.id}.svg`} alt={d.id} />
                                                {d.name}
                                            </div>
                                        </Select.Option>)
                                    })
                                }
                            </Select>
                            <label className={countryLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_COUNTRY' })}</label>
                            <div className={countryInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <img src={userNameIcon} alt="username" className="m-form-username-icon" />
                    <Form.Item
                        name="fullname"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME_REQUIRED' }) },
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME_INVALID' }) }]}>
                        <div
                            className="m-float-label"
                            onBlur={() => setFullNameFocus(false)}
                            onFocus={() => setFullNameFocus(true)}
                        >
                            <Input className="m-input" value={fullNameInput} onChange={e => setFullNameInput(e.target.value)} />
                            <label className={fullNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_FULLNAME' })}</label>
                            <div className={fullNameInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="m-register-form-item">
                    <div className="m-register-name-note"><FormattedMessage id='AUTH_REGISTER_NAME_NOTE' /></div>
                </div>
                <div className="m-register-form-item">
                    <img src={dobIcon} alt="date of birth" className="m-form-calendar-icon" />
                    <div
                        className="m-float-label m-dob-float-label"
                        onBlur={() => setDobFocus(false)}
                        onFocus={() => setDobFocus(true)}
                    >
                        <Form.Item
                            name="dob">
                            <div className="m-float-label">
                                <DatePicker
                                    className="m-input"
                                    onChange={e => setDobInput(e ? e.toString() : '')}
                                    disabledDate={(current) => {
                                        return moment().add(-18, 'years') < current ||
                                            moment() <= current;
                                    }}
                                />
                                <label className={dobLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_DOB' })}</label>
                                <div className={`${dobInputClass} m-dob-input`}></div>
                            </div>

                        </Form.Item>

                    </div>
                </div>
                <RegisterDisclaimer />
                <Form.Item className="m-form-button">
                    <Button block type="primary" htmlType="submit" className="m-register-next-btn" loading={loading}>
                        <FormattedMessage id={"AUTH_REGISTER_TNC_BTN_TXT"} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default RegisterMobileStep2;
