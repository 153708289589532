import CountryThai from '../../../../assets/images/language-thailand.png';
import CountrySg from '../../../../assets/images/language-singapore.png';
import CountryPh from '../../../../assets/images/language-philippines.png';

export const regionLanguageList = [
    {
        id: 'TH',
        currency: 'THB',
        name: 'Thailand',
        icon: CountryThai,
        language: ['en', 'th']
    },
    {
        id: 'SG',
        currency: 'SGD',
        name: 'Singapore',
        icon: CountrySg,
        language: ['en', 'zh-CN']
    },
    {
        id: 'PH',
        currency: 'PHP',
        name: 'Philippines',
        icon: CountryPh,
        language: ['en']
    }
]