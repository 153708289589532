
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import './AlertDisplay.less';

interface Props {
    alertType: string,
    message: string
}

const AlertDisplay = ({ alertType, message }: Props) => {

    const returnAlertType = () => {
        switch (alertType) {
            case 'success':
                return 'success';
            case 'error':
                return 'error';
            case 'warning':
                return 'warning';
            default:
                return 'info'
        }
    }

    return (
        <Alert className="alert-display" message={<FormattedMessage id={message} />} type={returnAlertType()} showIcon />
    )
}

export default AlertDisplay;