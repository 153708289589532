
import { FormattedMessage } from 'react-intl';
import '../GeneralFooter.less';

const FooterForthRow = () => {
    const year = new Date().getFullYear().toString();

    return (
        <div className='footer-forth-row'>
            <div className='footer-forth-row-copyright'>
                <FormattedMessage id='FOOTER_COPYRIGHT_CONTENT' values={{
                    year: new Date().getFullYear().toString()
                }} />
            </div>
        </div>
    )
}

export default FooterForthRow;