
import "../Auth.less";
import { Alert, Button, Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from 'react';
import passwordIcon from '../../../assets/images/icon-form-password.png';
import userNameIcon from '../../../assets/images/icon-form-username.png';
import affReferralIcon from '../../../assets/images/icon-form-affiliate.png';
import RegisterDisclaimer from "./RegisterDisclaimer";
import { encode } from "base-64";
import { useMutation } from "@apollo/client";
import { CHECK_USERNAME } from "../auth.gql";
import { useLocation } from "react-router-dom";

interface Props {
    step: number;
    next: () => void;
}

const RegisterStep1 = ({ next, step }: Props) => {
    const [form1] = Form.useForm();
    const intl = useIntl();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [userNameFocus, setUserNameFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
    const [affReferralFocus, setAffReferralFocus] = useState(false);
    const [userNameInput, setUserNameInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [affReferralInput, setAffReferralInput] = useState(query.get('refId') ?? "");
    const [checkUsername, { data, loading }] = useMutation(CHECK_USERNAME, { fetchPolicy: 'no-cache' });
    const [error, setError] = useState<string>();

    const onFinish = (fieldsValue: any) => {
        checkUsername({
            variables: {
                username: fieldsValue.username,
                referralId: affReferralInput
            }
        })
    };

    useEffect(() => {
        if (data && data.checkUsername.status === 'SUCCESS') {
            if (!data.checkUsername.isExist) {
                const registerParam = {
                    username: form1.getFieldValue('username'),
                    password: encode(form1.getFieldValue('password')),
                    affiliate: affReferralInput ?? ''
                };

                localStorage.setItem("regParam", JSON.stringify(registerParam));
                next();
            } else {
                setError(intl.formatMessage({ id: 'ERROR_USER_EXISTS' }))
            }
        }
        else if (data) {
            setError(intl.formatMessage({ id: `ERROR_${data?.checkUsername?.status}` }));
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const userNameLabelClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "label label-float" : "label";
    const userNameInputClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "input-underline" : "";

    const passwordLabelClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "label label-float" : "label";
    const passwordInputClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "input-underline" : "";

    const confirmPasswordLabelClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "label label-float" : "label";
    const confirmPasswordInputClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "input-underline" : "";

    const affReferralLabelClass = affReferralFocus || (affReferralInput && affReferralInput.length !== 0) ? "label label-float" : "label";
    const affReferralInputClass = affReferralFocus || (affReferralInput && affReferralInput.length !== 0) ? "input-underline" : "";

    return (
        <>
            <div className="register-form-title"><FormattedMessage id='AUTH_REGISTER_STEP1' /></div>
            <Form
                form={form1}
                layout="vertical"
                onFinish={onFinish}
            >
                {error && <Alert
                    className="auth-error"
                    message={<FormattedMessage id={error} />}
                    type="error"
                    showIcon
                />}
                <div className="register-form-item">
                    <img src={userNameIcon} alt="username" className="form-username-icon" />
                    <Form.Item
                        name="username"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_REQUIRED' }) },
                            {
                                pattern: /^[A-Za-z][A-Za-z0-9]{3,14}$/,
                                message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_INVALID' })
                            }]}>
                        <div
                            className="float-label"
                            onBlur={() => setUserNameFocus(false)}
                            onFocus={() => setUserNameFocus(true)}
                        >
                            <Input maxLength={15} value={userNameInput} className="input" onChange={e => setUserNameInput(e.target.value)} />
                            <label className={userNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME' })}</label>
                            <div className={userNameInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="register-form-item">
                    <img src={passwordIcon} alt="password" className="form-password-icon" />
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                            {
                                pattern: /[A-Za-z0-9]{6,19}$/,
                                message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                            }]}>
                        <div
                            className="float-label"
                            onBlur={() => setPasswordFocus(false)}
                            onFocus={() => setPasswordFocus(true)}
                        >
                            <Input.Password maxLength={20} className="input" value={passwordInput} onChange={e => setPasswordInput(e.target.value)} autoComplete="new-password" />
                            <label className={passwordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD' })}</label>
                            <div className={passwordInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="register-form-item">
                    <img src={passwordIcon} alt="password" className="form-password-icon" />
                    <Form.Item
                        name="confirmpassword"
                        dependencies={['password']}
                        rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                            },
                        })]}>
                        <div
                            className="float-label"
                            onBlur={() => setConfirmPasswordFocus(false)}
                            onFocus={() => setConfirmPasswordFocus(true)}
                        >
                            <Input.Password className="input" value={confirmPasswordInput} onChange={e => setConfirmPasswordInput(e.target.value)} />
                            <label className={confirmPasswordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_CONFIRM_PASSWORD' })}</label>
                            <div className={confirmPasswordInputClass}></div>
                        </div>
                    </Form.Item>
                </div>
                <div className="register-form-item">
                    <img src={affReferralIcon} alt="affiliate" className="form-aff-icon" />
                    <Form.Item
                        name="affiliate"
                        rules={[
                            { min: 5, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_AFF_REFERRAL_INVALID' }) }]}>
                        <div
                            className="float-label"
                            onBlur={() => setAffReferralFocus(false)}
                            onFocus={() => setAffReferralFocus(true)}
                        >
                            <Input disabled={query.get('refId') ? true : false} className="input"
                                value={affReferralInput} onChange={e => setAffReferralInput(e.target.value)} />
                            <label className={affReferralLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_AFF_REFERRAL' })}</label>
                            <div className={affReferralInputClass}></div>
                        </div>
                    </Form.Item>
                </div>

                <RegisterDisclaimer />
                <Form.Item className="form-button">
                    <Button block type="primary" htmlType="submit" className="register-next-btn" loading={loading}>
                        <FormattedMessage id={"AUTH_REGISTER_TNC_BTN_TXT"} />
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default RegisterStep1;
