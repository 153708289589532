import { Form, Radio } from "antd";
import { FormattedMessage } from "react-intl";
import CopyButton from "../Button/CopyButton";
import './RadioGroups.less';

interface Props {
    radioOptionList: any;
    formName: string;
    isImage: boolean;
    radioClassName: string;
    labelName: string;
    onChange: any;
    required?: boolean;
}

const RadioGroups = ({ radioOptionList, formName, isImage, radioClassName, labelName, onChange, required = true }: Props) => {

    const renderRadioBtn = (option: any) => {
        switch (radioClassName) {
            case 'imgRadioRow':
                return <Radio.Button value={option.value} className={radioClassName} key={option.value}>
                    <div className="radio-btn-row-container">
                        <img src={option.icon} alt={option.name} className="radio-icon" />
                        <div className="img-radio-txt">{option.name}</div>
                    </div>
                </Radio.Button>;
            case 'copyTxtRadio':
                return <div key={option.accId}> <Radio.Button value={option.accId} className={radioClassName} key={option.accId}>
                    <div className='radio-btn-copy-container'>
                        <div className='radio-btn-row-txt'>{option?.name}</div>
                        <CopyButton copyText={option?.name}
                            copyFrom={labelName === 'DASHBOARD_DEPOSIT_CRYPTO_SEND_ADDR' ? 'DASHBOARD_DEPOSIT_CRYPTO_ADDR_COPIED' : 'DASHBOARD_DEPOSIT_BANK_ACCOUNT_COPIED'} />
                    </div>
                </Radio.Button>
                    <Radio.Button value={option.acctNumber} className={radioClassName} key={option.acctNumber}>
                        <div className='radio-btn-copy-container'>
                            <div className='radio-btn-row-txt'>{option?.acctNumber}</div>
                            <CopyButton copyText={option?.acctNumber}
                                copyFrom={labelName === 'DASHBOARD_DEPOSIT_CRYPTO_SEND_ADDR' ? 'DASHBOARD_DEPOSIT_CRYPTO_ADDR_COPIED' : 'DASHBOARD_DEPOSIT_BANK_ACCOUNT_COPIED'} />
                        </div>
                    </Radio.Button></div>;
            default:
                return <div className="radio-btn-container" key={option.value}>
                    <Radio.Button value={option.value} className={radioClassName} key={option.value}>
                        {isImage ?
                            <img src={option.icon} alt={option?.name} className="radio-icon" />
                            : radioClassName === 'numRadio' ? option.icon : <FormattedMessage id={option.icon} />
                        }
                    </Radio.Button>
                    {option && option?.name &&
                        <div className="img-radio-txt"><FormattedMessage id={option.name} /></div>
                    }
                </div>
        }
    }

    return (
        <div className="form-radio-grp-items">
            <div className={radioClassName === 'txtRadio' ? 'form-txt-radio-grp-label' : 'form-radio-grp-label'}>
                {labelName ?
                    <FormattedMessage id={labelName} /> :
                    <></>
                }
            </div>
            <Form.Item
                name={formName}
                rules={required ? [{ required: true, message: 'Please pick an item!' }] : []}
            >
                <Radio.Group onChange={onChange} className={radioClassName === 'txtRadio' ? 'form-txt-radio-grp' : 'form-radio-grp'}>
                    {radioOptionList && radioOptionList.length > 0 && radioOptionList?.map((option: any) => {
                        return (renderRadioBtn(option));
                    })
                    }
                </Radio.Group>
            </Form.Item>
        </div>
    )
}

export default RadioGroups;