
import { Layout } from 'antd';
import './GeneralHeader.less';
import HeaderRightFirstRow from './HeaderElements/HeaderRightFirstRow';
import HeaderLeft from './HeaderElements/HeaderLeft';

const { Header } = Layout;

const GeneralHeader = () => {
  return (
    <Header className="site-layout-header" >
      <div className='header-left-elements'>
        <HeaderLeft />
      </div>
      <div className='header-right-elements'>
        <HeaderRightFirstRow />
      </div>
    </Header>
  )
}

export default GeneralHeader;