import { Button, Dropdown, Form } from 'antd';
import { SET_DEFAULT_CURRENCY, useAuth } from '../../../../hooks/auth/AuthContext';
import '../GeneralHeader.less';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useNotification from '../../../../hooks/layout/useNotification';
import useSpinner from '../../../../hooks/layout/useSpinner';
import { SET_CURRENCY, SET_LANGUAGE, useLayout } from '../../../../hooks/layout/LayoutContext';
import HeaderMobileDrawer from './HeaderDrawer';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import LoginModal from './LoginModal';
import HeaderLanguageDropdown from './HeaderLanguageDropdown';

import CountryThai from '../../../../assets/images/language-thailand.png';
import CountrySg from '../../../../assets/images/language-singapore.png';
import CountryPh from '../../../../assets/images/language-philippines.png';

const HeaderRightFirstRow = () => {
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const { setLoading } = useSpinner();
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const { setErrorNotification } = useNotification();
    const [langIcon, setLangIcon] = useState(CountryThai);
    const [enClassName, setEnClassName] = useState('header-language-item-selected');
    const [thClassName, setThClassName] = useState('header-language-item');
    const [loginModalVIsible, setLoginModalVisible] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    useEffect(() => {
        let language = localStorage.getItem('pfxl');
        if (!language) {
            language = layoutState.locale;
        }
    }, [])

    const onFinish = () => {
        setLoginModalVisible(true);
    };

    const onRegClick = () => {
        navigate('/home/register');
    }

    const onLanguageClick = (language: string, currency: string) => {
        localStorage.setItem('pfxl', language);
        localStorage.setItem('pfxc', currency);
        layoutDispatch({ type: SET_LANGUAGE, payload: language })
        layoutDispatch({ type: SET_CURRENCY, payload: currency });
        authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });

        setRegionFlag(currency);
    }

    const setRegionFlag = (currency: string) => {
        console.log("setRegionFlag", currency)
        if (currency.toUpperCase() === 'SGD') {
            setLangIcon(CountrySg);
            localStorage.setItem('pfxc', currency);
        } else if (currency.toUpperCase() === 'PHP') {
            setLangIcon(CountryPh);
            localStorage.setItem('pfxc', currency);
        } else {
            setLangIcon(CountryThai)
            localStorage.setItem('pfxc', currency);
        }
    }

    const onMenuClick = (dest: string) => {
        navigate(`/home/${dest}`)
    }

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    useEffect(() => {
        console.log("layoutState.currency", layoutState.currency)
        if (layoutState.currency) {
            setRegionFlag(layoutState.currency);
        }
    }, [layoutState.currency]);


    return (
        <>
            <Form
                name="basic"
                form={form}
                autoComplete="off"
                initialValues={{ isSMSLogin: '0' }}
                onFinish={onFinish}>
                <div className='header-right-first-row'>
                    <>
                        <div className='home-menu-container'>
                            <div className='home-menu-items' onClick={() => { onMenuClick('commission-plans') }}><FormattedMessage id='HEADER_COMMISSION' /></div>
                            <div className='home-menu-items' onClick={() => { onMenuClick('faq') }}><FormattedMessage id='HEADER_FAQ' /></div>
                            <div className='home-menu-items' onClick={() => { onMenuClick('contact-us') }}><FormattedMessage id='HEADER_CONTACT' /></div>
                            <div className='home-menu-items' onClick={() => { onMenuClick('terms') }}><FormattedMessage id='HEADER_TNC' /></div>
                            <div className='home-menu-items' onClick={() => { onMenuClick('product') }}><FormattedMessage id='HEADER_PRODUCT' /></div>
                        </div>
                        {/* <Form.Item
                            label=""
                            name="username"
                        // rules={[
                        //     { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                        //     { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }]}
                        >
                            <Input type={"text"} placeholder={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })} className='auth-input-verification' />
                        </Form.Item>
                        <div>
                            <Form.Item
                                label=""
                                name="password"
                            // rules={[{ required: true, message: <FormattedMessage id="LOGIN_PASSWORD_REQUIRED" /> }]}
                            >
                                <Input.Password className='auth-input-password' placeholder={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })} />
                            </Form.Item>
                            <div className="header-forgot-password" onClick={onForgotPasswordClick}><FormattedMessage id='LOGIN_FORGOT_PASSWORD' /></div>
                        </div> */}
                        <div className="login-register-wrapper">
                            <Form.Item>
                                <Button className="login-btn" type="primary" htmlType="submit">
                                    <FormattedMessage id={'BTN_LOGIN'} />
                                </Button>
                            </Form.Item>
                            <Button className="join-btn" type="default" htmlType="button" onClick={onRegClick}>
                                <FormattedMessage id='BTN_REGISTER' />
                            </Button>
                        </div>
                    </>
                    <Dropdown overlay={<HeaderLanguageDropdown containerName={'language-dropdown'} onLanguageClick={onLanguageClick} />} className="header-language-dropdown" trigger={['hover']}>
                        <div className={authState?.isLogin ? 'header-language-login-container' : 'header-language-container'}>
                            <img className="header-language-img" src={langIcon} alt="language" />
                            <DownOutlined />
                        </div>
                    </Dropdown>
                    {/* <div className="header-language-wrapper">
                        <div className="header-language" ><img className="header-language-img" src={langIcon} alt="language" /></div>
                        <div className='header-language-options'>
                            <div className={thClassName} onClick={() => onLanguageClick('th')}><FormattedMessage id='LANGUAGE_TH' /></div>
                            <div className={enClassName} onClick={() => onLanguageClick('en')}><FormattedMessage id='LANGUAGE_EN' /></div>
                        </div>
                    </div> */}
                    <div className="mobile-menu-btn" onClick={showDrawer}>
                        <MenuOutlined />
                    </div>
                </div>
            </Form>
            <HeaderMobileDrawer isDrawerVisible={isDrawerVisible} setIsDrawerVisible={setIsDrawerVisible} />
            <LoginModal modalVisible={{ visible: loginModalVIsible, setVisible: setLoginModalVisible }} setLoading={setLoading} />
        </>
    )
}

export default HeaderRightFirstRow;