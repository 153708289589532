
import { useMutation, useQuery } from '@apollo/client';
import { Dropdown, Form, Input, Menu, Modal } from 'antd';
import { forEach } from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useNotification from '../../../../../hooks/layout/useNotification';
import useSpinner from '../../../../../hooks/layout/useSpinner';
import AlertDisplay from '../../../../../shared/components/alerts/AlertDisplay';
import PrimaryButton from '../../../../../shared/components/Button/PrimaryButton';
import '../../../Dashboard.less';
import DashboardInputs from '../../../shared/dashboard-str-input/DashboardInput';
import { ADD_BANK, GET_BANKS, REQUEST_OTP } from '../DashboardWithdrawDetail.gql';
import '../DashboardWithdrawDetail.less';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../../../../hooks/auth/AuthContext';
import CodeRequestButton from '../../../../../shared/components/Button/CodeRequestButton';
import { VerificationType } from '../../../../../shared/helpers/general-helpers';


interface Props {
    refetch: any;
    modalVisible: any;
    setModalVisible: any;
}

const DashboardWithdrawAddBankModal = ({ refetch, modalVisible, setModalVisible }: Props) => {
    const { authState } = useAuth();
    const { setLoading } = useSpinner();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [bankForm] = Form.useForm();
    const intl = useIntl();
    const [selectedBankName, setSelectedBankName] = useState('');
    const [selectedBankVal, setSelectedBankVal] = useState('');
    const { data: banksData, loading: banksLoading } = useQuery(GET_BANKS,
        { fetchPolicy: "no-cache" });
    const [addNewBank, { data: addNewBankData, loading: addNewBankLoading, error: addNewBankError }]
        = useMutation(ADD_BANK, { errorPolicy: "all" });
    const [bankList, setBankList] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [requestOTPCode, { data: requestOTPCodeData, loading: requestOTPCodeLoading, error: requestOTPCodeError }] = useMutation(REQUEST_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [otpInput, setOtpInput] = useState("");
    const [cookies] = useCookies();
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [countdownTime, setCountdownTime] = useState<number>(179);
    const [isRequestCode, setIsRequestCode] = useState(false);
    const [requestedCode, setRequestedCode] = useState(false);

    useEffect(() => {
        if (addNewBankLoading || banksLoading)
            setLoading(true);
        else
            setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewBankLoading, banksLoading]);

    useEffect(() => {
        if (banksData && banksData.banks) {
            let bank: any = [];
            forEach(banksData.banks, (banks) => {
                bank.push({
                    value: banks.id,
                    name: banks.name
                })
            });

            setBankList(bank);
        }
    }, [banksData])

    useEffect(() => {
        if (addNewBankData && addNewBankData.createBankAccount && addNewBankData.createBankAccount.status) {
            if (addNewBankData.createBankAccount.status === 'SUCCESS') {
                setModalVisible(false);
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_ADD_BANK_SUCCESS' }));
                if (refetch) {
                    refetch();
                }
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_${addNewBankData.createBankAccount.status}` }));
            }
        }
    }, [addNewBankData])

    const onFinish = (fieldsValue: any) => {
        if (!selectedBankVal) {
            setHasError(true);
            setErrorMessage('DASHBOARD_WITHDRAW_DETAIL_BANK_REQUIRED');
        } else {
            const variables: any = {
                createBankAccountInput: {
                    bankId: selectedBankVal,
                    accountName: fieldsValue.wd_acc,
                    accountNumber: fieldsValue.wd_acc_num,
                    withdrawalMethod: 'BANK_TRANSFER',
                    verificationCode: fieldsValue.otp ?? '',
                    verificationType: VerificationType.PHONE
                },
            };

            addNewBank({ variables })
        }
    }

    const renderBankDropdown = (bankList: any[]) => {
        return <Menu
            className="dashboard-wd-bank-menu">
            {bankList.map((bank) => {
                return <Menu.Item key={bank.value} onClick={() => menuItemClick(bank.name, bank.value)} >
                    <div className='wd-bank-child-menu'>
                        <div className='wd-bank-child-menu-name'>{bank.name}</div>
                    </div>
                </Menu.Item>
            })}
        </Menu>;
    }

    const menuItemClick = (name: string, value: string) => {
        setSelectedBankName(name);
        setSelectedBankVal(value);
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    const requiredRules = (input: string) => {
        let msgId = '';
        switch (input) {
            case 'wd_acc':
                msgId = 'DASHBOARD_WITHDRAW_DETAIL_ACCOUNT_REQUIRED';
                break;
            case 'wd_acc_num':
                msgId = 'DASHBOARD_WITHDRAW_DETAIL_ACCOUNT_NUM_REQUIRED';
                break;
        }

        return [
            { required: true, message: intl.formatMessage({ id: msgId }) },
        ]
    }

    let otpRules: any = [
        { required: true, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' }) },
        {
            max: 6, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' })
        },
    ]

    const requestStatus = () => {
        let phone = authState?.userProfile?.phone ?? '';

        if (!phone) {
            setErrorNotification(intl.formatMessage({ id: 'OTP_VERIFICATION_ERROR_REGISTER' }));
            setOtpValidationError(true);
        } else {
            setOtpValidationError(false);
            setRequestedCode(true);
            requestOTPCode({
                variables: {
                    verificationType: VerificationType.PHONE,
                },
            });
        }
    };

    useEffect(() => {
        if (requestOTPCodeData) {
            if (requestOTPCodeData.requestOtp.status !== "SUCCESS") {
                if (requestOTPCodeData.requestOtp.status === 'PHONE_NUMBER_EXIST') {
                    setErrorNotification(intl.formatMessage({ id: 'PHONE_NUMBER_EXIST' }));
                }
                else {
                    setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
                }
            } else {
                const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

                if (cookiesCountdownTimer) {
                    setCountdownTime(+cookiesCountdownTimer);
                }

                setIsRequestCode(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeData]);

    return (
        <>
            <Modal
                className="dashboard-wd-modal"
                visible={modalVisible}
                closable={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose>
                <div className="dashboard-wd-modal-title"><FormattedMessage id='DASHBOARD_WITHDRAW_DETAIL_CARD_DETAIL' /></div>
                {hasError && errorMessage &&
                    <AlertDisplay message={errorMessage} alertType={'error'} />
                }
                <Form
                    name="basic"
                    form={bankForm}
                    autoComplete="off"
                    onFinish={onFinish}>
                    <div className='dashboard-wd-form-items'>
                        <div className='dashboard-wd-form-label'><FormattedMessage id={'DASHBOARD_WITHDRAW_DETAIL_FILTER_BANK'} /></div>
                        <Form.Item
                            name="wd_bank"
                        >
                            <Dropdown overlay={renderBankDropdown(bankList)} className="dashboard-wd-add-bank-dropdown" trigger={['hover', 'click']}>
                                <Input
                                    value={selectedBankName}
                                    className='dashboard-wd-input'
                                    maxLength={100}
                                    placeholder={intl.formatMessage({ id: "DASHBOARD_WITHDRAW_DETAIL_FILTER_BANK_PLACEHOLDER" })}
                                />
                            </Dropdown>
                        </Form.Item>
                    </div>
                    <DashboardInputs rules={requiredRules('wd_acc')} inputType='string' label='DASHBOARD_WITHDRAW_DETAIL_FILTER_ACC' formItemName='wd_acc' inputPlaceholder={intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_FILTER_ACC_PLACEHOLDER' })} />
                    <DashboardInputs maxLength={16} rules={requiredRules('wd_acc_num')} inputType='string' label='DASHBOARD_WITHDRAW_DETAIL_FILTER_ACC_NUM' formItemName='wd_acc_num' inputPlaceholder={intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_FILTER_ACC_NUM_PLACEHOLDER' })} />
                    {!authState?.userProfile?.phoneVerified &&
                        <div className="dashboard-wd-form-items">
                            <div className='dashboard-wd-otp-form-label'><FormattedMessage id={'FORGOT_PASSWORD_OTP'} /></div>
                            <div className='otp-input-btn-container'>
                                <Form.Item
                                    name="otp"
                                    rules={otpRules}
                                >
                                    <Input
                                        maxLength={8}
                                        className="dashboard-otp-input"
                                    />
                                </Form.Item>
                                {/* <DashboardInputs rules={otpRules} inputType='otp' label='FORGOT_PASSWORD_OTP' formItemName='otp' inputPlaceholder={''} /> */}
                                <CodeRequestButton
                                    type={VerificationType.PHONE}
                                    hasError={otpValidationError ? true : requestOTPCodeError ? true : false}
                                    requestStatus={requestStatus}
                                    isPinMatched={true}
                                    requestOTPCodeLoading={requestOTPCodeLoading}
                                    isRequestCode={isRequestCode}
                                    setIsRequestCode={setIsRequestCode}
                                />
                            </div>
                        </div>
                    }
                    <div className='dashboard-wd-form-items'>
                        <div className='dashboard-wd-form-label'>
                        </div>
                        <Form.Item>
                            <PrimaryButton btnText={'SAVE_BTN'} isFormBtn={true} onClick={() => { }} />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default DashboardWithdrawAddBankModal;