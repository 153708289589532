
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import './PrimaryButton.less';

interface Props {
    btnText: string;
    isFormBtn: boolean;
    onClick: any;
    additionalClassName?: string
    loading?: boolean
}

const PrimaryButton = ({ btnText, isFormBtn, onClick, additionalClassName = "primary-btn", loading }: Props) => {
    return (
        <>
            <Button block type="primary" htmlType={isFormBtn ? "submit" : "button"} className={additionalClassName} onClick={onClick} loading={loading}>
                <FormattedMessage id={btnText} />
            </Button>
        </>
    )
}

export default PrimaryButton;