
import { Form, Input, InputNumber } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../Dashboard.less';
import './DashboardInput.less';

interface Props {
    value?: string;
    label: string;
    formItemName: string;
    inputPlaceholder: any;
    inputType: string;
    rules?: any[];
    disabled?: boolean;
    max?: number;
    min?: number;
    minMaxMsg?: string;
    onDepositAmtChanged?: (value: number) => void;
    maxLength?: number;
}

const DashboardInputs = ({ maxLength= 100, minMaxMsg, max, min, value, label, formItemName, inputPlaceholder, inputType, rules = [], disabled = false, onDepositAmtChanged }: Props) => {
    const intl = useIntl();

    const renderInput = () => {
        switch (inputType) {
            case 'otp':
                return <Input
                maxLength={8}
                className="dashboard-otp-input"
                placeholder={inputPlaceholder}
                disabled={disabled}
            />
            case 'string':
                return <Input
                    maxLength={maxLength}
                    className="dashboard-str-input"
                    placeholder={inputPlaceholder}
                    disabled={disabled}
                />
            case 'number':
                return <InputNumber
                    maxLength={50}
                    className="dashboard-num-input"
                    placeholder={inputPlaceholder}
                    disabled={disabled}
                    value={value ? +value : 0}
                    onChange={onDepositAmtChanged}
                />
            case 'password':
            case 'newPassword':
            case 'confirmPassword':
                return <Input.Password className='dashboard-password-input' placeholder={inputPlaceholder} />
            default:
                return <Input
                    maxLength={100}
                    className="dashboard-str-input"
                    placeholder={intl.formatMessage({ id: inputPlaceholder })}
                    disabled={disabled}
                />
        }
    }

    return (
        <div className='dashboard-input-container'>
            <div className='dashboard-input-label'><FormattedMessage id={label} /></div>
            <Form.Item
                className='dashboard-input-form-item'
                name={formItemName}
                rules={inputType === 'newPassword' ? [...rules, ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (getFieldValue('password_current') === value) {
                            return Promise.reject(new Error(intl.formatMessage({ id: 'NEW_PASSWORD_CANNOT_SAME_WITH_OLD' })));
                        }
                        return Promise.resolve();
                    },
                })] : inputType === 'confirmPassword' ? [...rules,
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password_new') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(intl.formatMessage({ id: 'PASSWORD_NOT_MATCHED' })));
                    },
                })] : inputType === 'number' && min && max ? [...rules,
                ({
                    validator(_, value) {
                        if (value && (value > max)) {
                            return Promise.reject(new Error(intl.formatMessage({ id: minMaxMsg },
                                {
                                    0: intl.formatMessage({ id: 'DASHBOARD_MAXIMUM' }),
                                    1: max.toString()
                                })));
                        }
                        if ((value || value === 0) && (value < min)) {
                            return Promise.reject(new Error(intl.formatMessage({ id: minMaxMsg },
                                {
                                    0: intl.formatMessage({ id: 'DASHBOARD_MINIMUM' }),
                                    1: min.toString()
                                })));
                        }
                        return Promise.resolve();
                    },
                })] : rules}
            >
                {
                    renderInput()
                }
            </Form.Item>
        </div>
    )
}

export default DashboardInputs;