import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import product1 from '../../../assets/images/product-01.jpg';
import product2 from '../../../assets/images/product-02.jpg';
import product3 from '../../../assets/images/product-03.jpg';
import product4 from '../../../assets/images/product-04.jpg';

const Products = () => {
    const navigate = useNavigate();

    const onRegClick = () => {
        navigate('/home/register');
    }
    
    return (
        <div className="home-products">
            <div className="home-products-title"><FormattedMessage id='HOME_PRODUCT_TITLE' /></div>
            <div className="home-products-content"><FormattedMessage id='HOME_PRODUCT_CONTENT' /></div>
            <div className="home-products-card-container">
                <div className="home-products-card">
                    <div className="products-card-img"><img alt="register" src={product1} /></div>
                    <div className="products-card-content-container">
                        <div className="products-card-title"><FormattedMessage id='HOME_PRODUCT_SPORT' /></div>
                        <div className="products-card-content"><FormattedMessage id='HOME_PRODUCT_SPORT_CONTENT' /></div>
                        <div className="products-card-btn" onClick={onRegClick}><FormattedMessage id='BTN_PROMOTE'/></div>
                    </div>
                </div>
                <div className="home-products-card">
                    <div className="products-card-img"><img alt="register" src={product2} /></div>
                    <div className="products-card-content-container">
                        <div className="products-card-title"><FormattedMessage id='HOME_PRODUCT_CASINO' /></div>
                        <div className="products-card-content"><FormattedMessage id='HOME_PRODUCT_CASINO_CONTENT' /></div>
                        <div className="products-card-btn" onClick={onRegClick}><FormattedMessage id='BTN_PROMOTE'/></div>
                    </div>
                </div>
                <div className="home-products-card">
                    <div className="products-card-img"><img alt="register" src={product3} /></div>
                    <div className="products-card-content-container">
                        <div className="products-card-title"><FormattedMessage id='HOME_PRODUCT_SLOTS' /></div>
                        <div className="products-card-content"><FormattedMessage id='HOME_PRODUCT_SLOTS_CONTENT' /></div>
                        <div className="products-card-btn" onClick={onRegClick}><FormattedMessage id='BTN_PROMOTE'/></div>
                    </div>
                </div>
                <div className="home-products-card">
                    <div className="products-card-img"><img alt="register" src={product4} /></div>
                    <div className="products-card-content-container">
                        <div className="products-card-title"><FormattedMessage id='HOME_PRODUCT_FISH' /></div>
                        <div className="products-card-content"><FormattedMessage id='HOME_PRODUCT_FISH_CONTENT' /></div>
                        <div className="products-card-btn" onClick={onRegClick}><FormattedMessage id='BTN_PROMOTE'/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products;