import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import "./TableStatisticContainer.less";
import NumberFormat from 'react-number-format';
import _ from 'lodash';

interface Props {
    statisticList: any[];
    isLoading: boolean;
}

const TableStatisticContainer = ({ statisticList, isLoading }: Props) => {
    return (
        <>{statisticList && statisticList.length > 0 &&
            <div className="statistic-container">
                {statisticList.map((stats) => {
                    return <div key={stats.key} className='statistic-item'>
                        <div className='statistic-item-title'>
                            <FormattedMessage id={stats.key} />:
                        </div>
                        <div className='statistic-item-value'>
                            {isLoading ? <Spin /> : <span><NumberFormat value={stats.value.toFixed(2) ?? '0'} displayType={'text'} thousandSeparator={true} /></span>}
                        </div>
                    </div>
                })}
            </div>
        }
        </>
    )
}

export default TableStatisticContainer;