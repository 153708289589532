
import { Layout } from 'antd';
import FooterContent from './FooterElements/FooterContent';
import FooterFirstRowLeft from './FooterElements/FooterFirstRowLeft';
import './GeneralFooter.less';
import FooterPaymentMethod from './FooterElements/FooterPaymentMethod';
import FooterCertification from './FooterElements/FooterCertification';
import FooterForthRow from './FooterElements/FooterForthRow';
import FooterSocialMedia from './FooterElements/FooterSocialMedia';
import FooterProviders from './FooterElements/FooterProviders';

const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer">
            <FooterForthRow />
        </Footer>
    )
}

export default GeneralFooter;