
import { Form, RadioChangeEvent, Select } from 'antd';
import '../../DashboardWithdraw.less';
import '../../../../Dashboard.less';
import './DashboardWithdrawForm.less';

import { FormattedMessage, useIntl } from 'react-intl';
import DashboardWithdrawRecord from '../dashboard-withdraw-record/DashboardWithdrawRecord';
import RadioGroups from '../../../../../../shared/components/radio-groups/RadioGroups';
import { useEffect, useState } from 'react';

import iconWithdrawBankTrf from '../../../../../../assets/images/icon-dashboard-withdraw-01.png';
import DashboardInputs from '../../../../shared/dashboard-str-input/DashboardInput';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PrimaryButton from '../../../../../../shared/components/Button/PrimaryButton';
import { GET_BANK_ACCOUNTS } from '../../../withdrawal-detail/DashboardWithdrawDetail.gql';
import { useMutation, useQuery } from '@apollo/client';
import { forEach } from 'lodash';
import useSpinner from '../../../../../../hooks/layout/useSpinner';
import { GET_WALLETS, SUBMIT_WITHDRAWAL } from '../../DashboardWithdraw.gql';
import useNotification from '../../../../../../hooks/layout/useNotification';
import moment from 'moment';
import DashboardWithdrawAddBankModal from '../../../withdrawal-detail/components/DashboardWithdrawAddBankModal';
import { SET_USER_WALLET, useAuth } from '../../../../../../hooks/auth/AuthContext';
import ViewMore from '../../../view-more/ViewMore';
interface Props {
    setActiveTab: any;
}

const DashboardWithdrawForm = ({ setActiveTab }: Props) => {
    const [form] = Form.useForm();
    const { setLoading } = useSpinner();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const intl = useIntl();
    const [selectedWithdrawOption, setSelectedWithdrawOption] = useState('banktrf');
    const { data: bankAccountsData, loading: bankAccountsLoading, refetch } = useQuery(GET_BANK_ACCOUNTS,
        { fetchPolicy: "no-cache" });
    // const { data: banksData, loading: banksLoading } = useQuery(GET_UNREGISTER_BANKS,
    //     { fetchPolicy: "no-cache" });
    const [submitWithdrawal, { data: submitWithdrawalData, loading: submitWithdrawalLoading }]
        = useMutation(SUBMIT_WITHDRAWAL,
            { errorPolicy: "all" });
    const [bankAccountDetailList, setBankAccountDetailList] = useState([]);
    // const [bankList, setBankList] = useState([]);
    const [bankAccountList, setBankAccountList] = useState([]);
    const [bankAccountName, setBankAccountName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [selectedBankAccId, setSelectedBankAccId] = useState('');
    const [newBankId, setNewBankId] = useState('');
    // const [isAddNewBank, setIsAddNewBank] = useState(false);
    const [refreshTable, setRefreshTable] = useState(moment().hours().toString());
    const [restOfFormVisible, setRestOfFormVisible] = useState(false);
    const [addBankModalVisible, setAddBankModalVisible] = useState(false);
    const { data: walletData, refetch: refetchWallet } = useQuery(GET_WALLETS,
        { fetchPolicy: "cache-and-network" });
    const { authDispatch } = useAuth();

    useEffect(() => {
        if (bankAccountsLoading || submitWithdrawalLoading)
            setLoading(true);
        else
            setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccountsLoading, submitWithdrawalLoading])

    useEffect(() => {
        if (submitWithdrawalData && submitWithdrawalData.createWithdraw && submitWithdrawalData.createWithdraw.status) {
            if (submitWithdrawalData.createWithdraw.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_SUCCESSFUL' }));
                refetchWallet();
            } else {
                if (submitWithdrawalData.createWithdraw.status === 'INVALID_WITHDRAWAL_REQUEST') {
                    setErrorNotification(intl.formatMessage({ id: `DASHBOARD_WITHDRAW_FAIL` }));
                } else {
                    setErrorNotification(intl.formatMessage({ id: `ERROR_${submitWithdrawalData.createWithdraw.status}` }));
                }
            }
            setRefreshTable(moment().unix().toString());
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitWithdrawalData])

    // useEffect(() => {
    //     if (banksData && banksData.unregisterBanks) {
    //         let bank: any = [];
    //         forEach(banksData.unregisterBanks, (banks) => {
    //             bank.push({
    //                 value: banks.id,
    //                 icon: banks.imageUrl,
    //             })
    //         });

    //         setBankList(bank);
    //     }
    // }, [banksData])

    useEffect(() => {
        let dispatchWalletData = {};
        if (walletData && walletData.wallets && walletData.wallets.wallets) {
            let walletList: any = [];
            let totalWalletBalance = 0;

            forEach(walletData.wallets.wallets, (wallet) => {
                totalWalletBalance += +wallet?.balance;
                walletList.push({
                    name: wallet.walletType,
                    value: wallet.id,
                    balance: wallet.balance,
                    userWalletType: wallet.userWalletType,
                    isMaintenance: wallet.isMaintenance
                });
            });

            let categoryList = walletData.wallets.walletCategory;

            dispatchWalletData = { type: SET_USER_WALLET, payload: { wallets: walletList, walletCategory: categoryList, totalBalance: +totalWalletBalance } }
        }

        if (walletData && walletData.wallets && walletData.wallets.userBonusTracker) {
            dispatchWalletData = { ...dispatchWalletData, bonusWalletType: walletData.wallets.userBonusTracker.userDepositBonus?.bonusSetting?.BonusSettingWalletType };
            authDispatch(dispatchWalletData);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletData])

    useEffect(() => {
        if (bankAccountsData && bankAccountsData.bankAccounts && bankAccountsData.bankAccounts.bankAccounts) {
            let accList: any = [];
            forEach(bankAccountsData.bankAccounts.bankAccounts, (bankAccount) => {
                accList.push({
                    icon: bankAccount.bank.imageUrl,
                    value: bankAccount.id,
                    name: bankAccount.bank.name,
                    bankId: bankAccount.bank.id
                })
            });

            setBankAccountDetailList(bankAccountsData.bankAccounts.bankAccounts);
            setBankAccountList(accList);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccountsData])

    const withdrawOptions = [
        {
            icon: iconWithdrawBankTrf,
            value: 'banktrf'
        },
    ]

    let withdrawAccNameNumRules = (type: string) => {
        return [
            {
                required: true,
                message: <FormattedMessage id={type === 'name' ? 'DASHBOARD_WITHDRAW_ACC_NAME_REQUIRED' : 'DASHBOARD_WITHDRAW_ACC_NUM_REQUIRED'} />,
            },
        ]
    };

    let withdrawAmtRules: any = [
        {
            required: true,
            message: <FormattedMessage id={'DASHBOARD_WITHDRAW_AMOUNT_REQUIRED'} />,
        },

        {
            pattern: /^\d+(\.\d+)?$/,
            message: <FormattedMessage id="FORM_INPUT_NUMBER_ONLY" />,
        },
        {
            type: 'number', min: 50, message: <FormattedMessage id="DASHBOARD_WITHDRAW_AMOUNT_MINMAX" values={{ 0: 'Minimum', 1: 50 }} />
        },
        {
            type: 'number', max: 50000, message: <FormattedMessage id="DASHBOARD_WITHDRAW_AMOUNT_MINMAX" values={{ 0: 'Maximum', 1: 50000 }} />
        },
    ];

    const onWithdrawOptionChange = (e: RadioChangeEvent) => {
        setSelectedWithdrawOption(e.target.value);
    };

    const onBankAccSelectChange = (value: string) => {
        let selectedBankAcc: any = bankAccountDetailList.filter((x: any) => x.id === value);

        if (selectedBankAcc) {
            setBankAccountName(selectedBankAcc[0].accountName);
            setBankAccountNumber(selectedBankAcc[0].accountNumber);
            setSelectedBankAccId(value);
            //setIsAddNewBank(false);
            setRestOfFormVisible(true);

            form.setFieldsValue({
                withdraw_acc: selectedBankAcc[0].accountName,
                withdraw_acc_num: selectedBankAcc[0].accountNumber,
            });
        }
    }

    const onAddBankChange = () => {
        setAddBankModalVisible(true);
        //setRestOfFormVisible(true);
    }

    // const onNewBankOptionChange = (e: RadioChangeEvent) => {
    //     setNewBankId(e.target.value);
    // }

    const onFinish = (fieldsValue: any) => {
        if (newBankId) {
            const variables: any = {
                createWithdrawInput: {
                    bankId: newBankId,
                    bankAccountName: fieldsValue.withdraw_acc,
                    bankAccountNumber: fieldsValue.withdraw_acc_num,
                    amount: fieldsValue.withdraw_amount
                },
            };
            submitWithdrawal({ variables });
        } else {
            const variables: any = {
                createWithdrawInput: {
                    bankAccountId: selectedBankAccId,
                    amount: fieldsValue.withdraw_amount
                },
            };
            submitWithdrawal({ variables });
        }
    };

    return (
        <>
            <div className="dashboard-withdraw-form-container">
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                    onFinish={onFinish}
                    initialValues={{ withdraw_option: selectedWithdrawOption }}>
                    <RadioGroups onChange={onWithdrawOptionChange} labelName={'DASHBOARD_WITHDRAW_OPTIONS'} radioOptionList={withdrawOptions} formName={'withdraw_option'} isImage={true} radioClassName={'imgRadio'} />
                    {/* {selectedWithdrawOption && bankAccountList && bankAccountList.length > 0 &&
                        <RadioGroups required={false} onChange={onBankAccRadioChange} labelName={''} radioOptionList={bankAccountList} formName={'withdraw_bank_acc'} isImage={true} radioClassName={'imgRadioRow'} />
                    } */}
                    {selectedWithdrawOption && bankAccountList && bankAccountList.length > 0 ?
                        <div className='withdraw-form-items'>
                            <div className='withdraw-form-item-label'><FormattedMessage id={'DASHBOARD_WITHDRAW_BANK'} /></div>
                            <Form.Item
                                className='withdraw-form-dropdown'
                                name="withdraw_bank_acc">
                                <Select
                                    className="withdraw-dropdown"
                                    onChange={onBankAccSelectChange}
                                    dropdownClassName="withdraw-wallet-dropdown-list"
                                    placeholder={intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_BANK_ACCOUNT_PLACEHOLDER' })}
                                >
                                    {bankAccountList?.map((val: any) => {
                                        return (
                                            <Select.Option key={val.value} value={val.value}>
                                                {val.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </div>
                        :
                        <div className='withdraw-form-items'>
                            <div className='withdraw-form-item-label'><FormattedMessage id={'DASHBOARD_WITHDRAW_BANK'} /></div>
                            <div className='withdraw-form-item-btn-container'>
                                <PrimaryButton additionalClassName='primary-btn withdraw-add-bank-btn' btnText={'DASHBOARD_WITHDRAW_ADD_BANK'} isFormBtn={false} onClick={onAddBankChange} />
                            </div>
                        </div>
                    }
                    {restOfFormVisible &&
                        <>
                            <DashboardInputs rules={withdrawAccNameNumRules('name')} inputType='string' label='DASHBOARD_WITHDRAW_ACC_NAME' formItemName='withdraw_acc' inputPlaceholder={''} disabled={bankAccountName ? true : false} />
                            <DashboardInputs rules={withdrawAccNameNumRules('num')} inputType='string' label='DASHBOARD_WITHDRAW_ACC_NO' formItemName='withdraw_acc_num' inputPlaceholder={''} disabled={bankAccountNumber ? true : false} />
                            <DashboardInputs rules={withdrawAmtRules} inputType='number' label='DASHBOARD_WITHDRAW_AMT' formItemName='withdraw_amount' inputPlaceholder={intl.formatMessage({ id: "DASHBOARD_WITHDRAW_MIN_MAX" }, {
                                0: intl.formatMessage({ id: "CURRENCY_SYMBOL_THB" }) + '50',
                                1: intl.formatMessage({ id: "CURRENCY_SYMBOL_THB" }) + '50000',
                            })} />
                            <div className='withdraw-form-items'>
                                <div className='withdraw-form-item-label'></div>
                                <div className='withdraw-amt-info-warning'>
                                    <ExclamationCircleOutlined />
                                    <div className='withdraw-amt-info-warning-txt'>
                                        <FormattedMessage id='DASHBOARD_WITHDRAW_DAILY_LIMIT' values={{
                                            0: intl.formatMessage({ id: "CURRENCY_SYMBOL_THB" }) + '50',
                                            1: intl.formatMessage({ id: "CURRENCY_SYMBOL_THB" }) + '50000',
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className='withdraw-form-items-btn'>
                                <div className='withdraw-form-item-label'>
                                </div>
                                <Form.Item>
                                    <PrimaryButton additionalClassName='primary-btn withdraw-submit-btn' btnText={'FORM_SUBMIT_BTN'} isFormBtn={true} onClick={() => { }} />
                                </Form.Item>
                            </div>
                        </>
                    }
                </Form>
                {/* <DashboardWithdrawRecord startDate={''}
                    endDate={''} pagination={false} isTrigger={refreshTable} /> */}
                {/* <ViewMore setActiveTab={setActiveTab} clickFrom={'withdraw'} /> */}
            </div>
            <DashboardWithdrawAddBankModal modalVisible={addBankModalVisible} setModalVisible={setAddBankModalVisible} refetch={refetch} />
        </>
    )
}

export default DashboardWithdrawForm;