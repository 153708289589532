
import { useQuery } from '@apollo/client';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import "../../Dashboard.less";
import "./MemberList.less";
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import ItemListPagination from '../../../../shared/components/item-list/ItemListPagination';
import useSpinner from '../../../../hooks/layout/useSpinner';
import moment from 'moment';
import { MEMBER_LIST } from './MemberList.gql';
import { client } from '../../../../apollo-client';
import { endOfDay, format, startOfDay, subMonths, startOfMonth } from "date-fns";
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import TablePageFilter from '../../../../shared/components/table-page-filter/TablePageFilter';

const MemberList = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const [pageNo, setPageNo] = useState<number>(1);
    const pageSize = process.env.REACT_APP_PAGE_SIZE ? +process.env.REACT_APP_PAGE_SIZE : 10;
    const [total, setTotal] = useState<number>(0);
    const [reportData, setReportData] = useState<any>();
    const [filters, setFilters] = useState({
        startDate: format(startOfDay(subMonths(startOfMonth(new Date()), 1)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        endDate: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    });
    const { data, loading, refetch } = useQuery(MEMBER_LIST, {
        variables: { pageNo, pageSize, memberFilterInput: filters },
        client, fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        setLoading(loading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        console.log('data', data);
        if (data && data.memberList) {
            setTotal(data?.memberList?.count ?? 0);

            if (data.memberList?.members && data.memberList?.members?.length > 0) {
                let memberData = data.memberList?.members.map((val: any) => {
                    return {
                        ...val,
                        key: val.id
                    };
                })

                setReportData(memberData);
            } else {
                setReportData([]);
            }
        }
    }, [data])

    const columns: any = [
        {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_ID' }),
            dataIndex: 'userName',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '-'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_NAME' }),
            dataIndex: 'name',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '-'}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_DEPOSIT' }),
            dataIndex: 'totalDeposit',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_VALID' }),
            dataIndex: 'totalTurnover',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                <NumberFormat
                                    value={parseFloat(text).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    fixedDecimalScale
                                    decimalScale={2}
                                /> :
                                '0'
                        }
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_GROUP' }),
            dataIndex: 'group',
            render: (group: any) => {
                return (
                    <Space size="middle">
                        {group?.name ? _.startCase(_.lowerCase(group?.name)) : '-'}
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_STATUS' }),
            dataIndex: 'isActive',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text === true ? intl.formatMessage({ id: 'MEMBER_ACTIVE' }) : intl.formatMessage({ id: 'MEMBER_SUSPEND' })}
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_URL' }),
            dataIndex: 'url',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {text ?? '-'}
                    </Space>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_AFF' }),
            dataIndex: 'affiliate',
            render: (affiliate: any) => {
                return (
                    <div>
                        <div><b><FormattedMessage id='MEMBER_AFF_LOGIN' /></b>{affiliate.userName}</div>
                        <div><b><FormattedMessage id='MEMBER_AFF_NAME' /></b>{affiliate.name}</div>
                    </div>
                )
            },
        }, {
            title: intl.formatMessage({ id: 'MEMBER_LIST_COLUMN_CREATED' }),
            dataIndex: 'createdAt',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text) ?
                                format(new Date(text), "yyyy-MM-dd HH:mm") :
                                '-'
                        }
                    </Space>
                )
            }
        },
    ]

    return (
        <div className="member-list-container">
            <TablePageFilter setFilters={setFilters} />
            <div className='member-list-total'>
                <div className='total-title'><b><FormattedMessage id='MEMBER_LIST_TOTAL' /></b></div>
                <div className='total-num'><NumberFormat value={total} displayType={'text'} thousandSeparator={true} /></div>
            </div>
            <ItemListPagination pagination={true} pageNo={pageNo} pageSize={pageSize} setPageNo={setPageNo} columns={columns} data={reportData || []}
                total={total} />
        </div>
    )
}

export default MemberList;