
import { FormattedMessage } from 'react-intl';
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import DashboardWithdrawForm from './components/dashboard-withdraw-form/DashboardWithdrawForm';
import './DashboardWithdraw.less';

interface Props {
    setActiveTab: any;
}

const DashboardWithdraw = ({ setActiveTab }: Props) => {
    const withdrawNoticeTitle = <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_TITLE'} />;
    const withdrawNoticeContent = [
        {
            id: 1,
            content: <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_CONTENT_1'} values={{
                0: <a href={`www.google.com`}
                    target="_blank" rel="noopener noreferrer"><FormattedMessage id={'DASHBOARD_NOTICE_LIVE_CHAT'} /></a>,
            }} />,
        },
        {
            id: 2,
            content: <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_CONTENT_2'} />,
        },
        {
            id: 3,
            content: <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_CONTENT_3'} />,
        },
        {
            id: 4,
            content: <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_CONTENT_4'} />,
        },
        {
            id: 5,
            content: <FormattedMessage id={'DASHBOARD_WITHDRAW_NOTICE_CONTENT_5'} values={{
                0: <a href={`www.google.com`}
                    target="_blank" rel="noopener noreferrer"><FormattedMessage id={'DASHBOARD_NOTICE_LIVE_CHAT'} /></a>,
            }} />
        },
    ]

    return (
        <div className="dashboard-withdraw-container">
            {/* <DashboardMenuTitle text={'DASHBOARD_WITHDRAW_TITLE'} /> */}
            <div className='dashboard-withdraw-content'>
                <DashboardWithdrawForm setActiveTab={setActiveTab} />
            </div>
        </div>
    )
}

export default DashboardWithdraw;