


import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input, Modal } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { encode } from 'base-64';
import { useCookies } from 'react-cookie';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import userNameIcon from '../../../../assets/images/icon-form-username.png';
import phoneIcon from '../../../../assets/images/icon-form-contact.png';
import passwordIcon from '../../../../assets/images/icon-form-password.png';
import Logo from '../../../../assets/images/logo.svg';
import PrimaryButton from '../../Button/PrimaryButton';
import { SET_LOGIN_STATUS, SET_REFERRAL_MODAL_SHOW, useAuth } from '../../../../hooks/auth/AuthContext';
import useNotification from '../../../../hooks/layout/useNotification';
import { ANON_FORGOT_PASSWORD_REQUEST_CODE, ANON_FORGOT_PASSWORD_SUBMIT, SET_PASSWORD, SIGN_IN } from '../../../../pages/auth/auth.gql';
import { setTokenCookie } from '../../../helpers/set-token-cookie.helper';
import { VerificationType } from '../../../helpers/general-helpers';
import { setGeneralCookie } from '../../../helpers/set-general-cookie.helper';
import PhoneInputFloatingLabel from '../../phone-input/Phone-Input-floating-label';
import CodeRequestButton from '../../Button/CodeRequestButton';

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    setLoading: any;
}

const LoginModal = ({ modalVisible, setLoading }: Props) => {
    const intl = useIntl();
    const { authDispatch } = useAuth();
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const [forgotPasswordForm] = Form.useForm();
    const navigate = useNavigate();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [currentDisplayForm, setCurrentDisplayForm] = useState('LOGIN');
    const [userNameFocus, setUserNameFocus] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);
    const [otpFocus, setOtpFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [confirmPasswordFocus, setConfirmPasswordFocus] = useState(false);
    const [userNameInput, setUserNameInput] = useState("");
    const [otpInput, setOtpInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [requestOTPCode, { data: requestOTPCodeData, loading: requestOTPCodeLoading, error: requestOTPCodeError }] = useMutation(ANON_FORGOT_PASSWORD_REQUEST_CODE, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [submitOTPCode, { data: submitOTPData, error: submitOTPCodeError }] = useMutation(ANON_FORGOT_PASSWORD_SUBMIT,
        { errorPolicy: 'all' });
    const [submitNewPassword, { data: setPasswordData }] = useMutation(SET_PASSWORD,
        { errorPolicy: 'all' });
    const [requestedCode, setRequestedCode] = useState(false);
    const [cookies] = useCookies();
    const [countdownTime, setCountdownTime] = useState<number>(179);
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [showPasswordPage, setShowPasswordPage] = useState(false);
    const [isRequestCode, setIsRequestCode] = useState(false);
    const [signIn, { data, loading, error: signInError }] = useMutation(SIGN_IN, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [dialCode, setDialCode] = useState('TH');

    useEffect(() => {
        setLoading(loading);// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    const onFinish = (fieldsValue: any) => {
        signIn({
            variables: {
                userName: fieldsValue.mobile_login_username,
                password: encode(fieldsValue.mobile_login_password)
            },
        });
    }

    useEffect(() => {
        if (data && data.signIn) {
            console.log('data', data);
            const { a, r, sid } = data.signIn;
            setTokenCookie(process.env.REACT_APP_ACCESS_TOKEN || "gmf-at", a);
            setTokenCookie(process.env.REACT_APP_REFRESH_TOKEN || "gmf-rt", r);
            setTokenCookie("gmf-sid", sid);
            authDispatch({ type: SET_LOGIN_STATUS, payload: true });
            authDispatch({ type: SET_REFERRAL_MODAL_SHOW, payload: true });
            modalVisible.setVisible(false);
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (signInError) {
            if (signInError.message?.indexOf("INVALID_LOGIN") > -1 || signInError.message?.indexOf("INVALID_PHONE_LOGIN") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_INVALID_LOGIN` }));
            } else if (signInError.message?.indexOf("ACCOUNT_LOCKED") > -1) {
                setErrorNotification(intl.formatMessage({ id: `AUTH_ERROR_ACCOUNT_LOCKED` }));
            } else if (signInError.message?.indexOf("ACCOUNT_SUSPENDED") > -1) {
                setErrorNotification(intl.formatMessage({ id: `CONTACT_CUSTOMER_SERVICE` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInError]);

    const onForgotPasswordClick = () => {
        setCurrentDisplayForm('OTP');
    }

    useEffect(() => {
        const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

        if (cookiesCountdownTimer) {
            setCountdownTime(+cookiesCountdownTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeLoading]);

    useEffect(() => {
        if (submitOTPCodeError) {
            setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
        } else if (submitOTPData && requestedCode) {
            if (submitOTPData.anonForgotPasswordSubmit.status === "SUCCESS") {

                setGeneralCookie(`c-cooldown-start-${VerificationType.PHONE}`, '59', 59);
                setShowPasswordPage(true);
            } else {
                setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitOTPData, submitOTPCodeError, requestedCode]);

    useEffect(() => {
        if (requestOTPCodeData) {
            if (requestOTPCodeData.anonForgotPasswordRequestCode.status === "SUCCESS") {
                const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

                if (cookiesCountdownTimer) {
                    setCountdownTime(+cookiesCountdownTimer);
                }

                setIsRequestCode(true);
            } else if (requestOTPCodeData.anonForgotPasswordRequestCode.status === "DATA_NOT_EXISTED") {
                setErrorNotification(intl.formatMessage({ id: "USERNAME_OR_PHONE_NUMBER_NOT_EXISTED" }));
            } else {
                setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeData]);

    useEffect(() => {
        if (setPasswordData && setPasswordData.setNewPassword) {
            if (setPasswordData.setNewPassword.status === 'SUCCESS') {
                setSuccessNotification(intl.formatMessage({ id: 'RESET_PASSWORD_SUCCESS' }));
                modalVisible.setVisible(false);
                setCurrentDisplayForm('LOGIN');
                setShowPasswordPage(false);
            } else {
                setErrorNotification(intl.formatMessage({ id: `RESET_PASSWORD_FAILED` }));
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPasswordData]);

    const onSubmitOTP = () => {
        submitOTPCode({
            variables: {
                userName: otpForm.getFieldValue('username') ?? '',
                verificationType: VerificationType.PHONE,
                phone: otpForm.getFieldValue('phone') ?? '',
                verificationCode: otpForm.getFieldValue('otp') ?? ''
            },
        });
    }

    const onSubmitNewPassword = (fieldsValue: any) => {
        submitNewPassword({
            variables: {
                userName: otpForm.getFieldValue('username') ?? '',
                phone: otpForm.getFieldValue('phone') ?? '',
                password: encode(fieldsValue.password) ?? ''
            }
        })
    }

    const requestStatus = () => {
        let userName = otpForm.getFieldValue('username');
        let phone = otpForm.getFieldValue('phone');

        if (!userName || !phone) {
            console.log('has otp error');
            setErrorNotification(intl.formatMessage({ id: 'OTP_VERIFICATION_ERROR' }));
            setOtpValidationError(true);
        } else {
            setOtpValidationError(false);
            setRequestedCode(true);
            requestOTPCode({
                variables: {
                    userName: otpForm.getFieldValue('username') ?? '',
                    verificationType: VerificationType.PHONE,
                    phone: otpForm.getFieldValue('phone') ?? ''
                },
            });
        }
    };

    const userNameLabelClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const userNameInputClass = userNameFocus || (userNameInput && userNameInput.length !== 0) ? "m-input-underline" : "";

    const otpLabelClass = otpFocus || (otpInput && otpInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const otpInputClass = otpFocus || (otpInput && otpInput.length !== 0) ? "m-input-underline" : "";

    const passwordLabelClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const passwordInputClass = passwordFocus || (passwordInput && passwordInput.length !== 0) ? "m-input-underline" : "";

    const confirmPasswordLabelClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "m-label m-label-float" : "m-label";
    const confirmPasswordInputClass = confirmPasswordFocus || (confirmPasswordInput && confirmPasswordInput.length !== 0) ? "m-input-underline" : "";

    const onModalClose = () => {
        setCurrentDisplayForm('');
        modalVisible.setVisible(false)
    }

    return (
        <>
            <Modal
                className="mobile-login-modal"
                visible={modalVisible.visible}
                closable={true}
                footer={null}
                onCancel={onModalClose}
                destroyOnClose
            >
                {currentDisplayForm === 'OTP' ?
                    <>
                        <div className='m-forgot-password-modal-container'>
                            <div className='m-forgot-password-modal-content-container'>
                                <div className='m-forgot-password-modal-title'>
                                    <FormattedMessage id='LOGIN_FORGOT_PASSWORD' />
                                </div>
                                <div className='m-forgot-password-modal-content'>
                                    {showPasswordPage ?
                                        <Form
                                            name="basic"
                                            form={forgotPasswordForm}
                                            onFinish={onSubmitNewPassword}
                                            autoComplete="off">
                                            <div className="m-forgot-password-form-item">
                                                <img src={passwordIcon} alt="password" className="m-form-password-icon" />
                                                <Form.Item
                                                    name="password"
                                                    rules={[
                                                        { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                                                        {
                                                            pattern: /[A-Za-z0-9]{6,19}$/,
                                                            message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                                                        }]}>
                                                    <div
                                                        className="m-float-label"
                                                        onBlur={() => setPasswordFocus(false)}
                                                        onFocus={() => setPasswordFocus(true)}
                                                    >
                                                        <Input.Password maxLength={20} className="m-input" value={passwordInput} onChange={e => setPasswordInput(e.target.value)} autoComplete="new-password" />
                                                        <label className={passwordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD' })}</label>
                                                        <div className={passwordInputClass}></div>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="m-forgot-password-form-item">
                                                <img src={passwordIcon} alt="password" className="m-form-password-icon" />
                                                <Form.Item
                                                    name="confirmpassword"
                                                    dependencies={['password']}
                                                    rules={[{ required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_REQUIRED' }) },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error(intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_NOT_MATCHED' })));
                                                        },
                                                    })]}>
                                                    <div
                                                        className="m-float-label"
                                                        onBlur={() => setConfirmPasswordFocus(false)}
                                                        onFocus={() => setConfirmPasswordFocus(true)}
                                                    >
                                                        <Input.Password className="m-input" value={confirmPasswordInput} onChange={e => setConfirmPasswordInput(e.target.value)} />
                                                        <label className={confirmPasswordLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_CONFIRM_PASSWORD' })}</label>
                                                        <div className={confirmPasswordInputClass}></div>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <Form.Item className="m-form-button">
                                                <div className='m-form-mid-button'>
                                                    <Button block type="primary" htmlType="submit" className="m-forgot-password-submit-btn">
                                                        <FormattedMessage id={"FORM_SUBMIT_BTN"} />
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                        :
                                        <Form
                                            name="basic"
                                            form={otpForm}
                                            autoComplete="off">
                                            <div className="m-forgot-password-form-item">
                                                <img src={userNameIcon} alt="username" className="m-form-username-icon" />
                                                <Form.Item
                                                    name="username"
                                                    rules={[
                                                        { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_REQUIRED' }) },
                                                        {
                                                            pattern: /^[A-Za-z][A-Za-z0-9]{3,14}$/,
                                                            message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME_INVALID' })
                                                        }]}>
                                                    <div
                                                        className="m-float-label"
                                                        onBlur={() => setUserNameFocus(false)}
                                                        onFocus={() => setUserNameFocus(true)}
                                                    >
                                                        <Input maxLength={15} value={userNameInput} className="m-input" onChange={e => setUserNameInput(e.target.value)} />
                                                        <label className={userNameLabelClass}>{intl.formatMessage({ id: 'AUTH_REGISTER_FORM_USERNAME' })}</label>
                                                        <div className={userNameInputClass}></div>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                            <div className="m-forgot-password-form-item">
                                                <img src={phoneIcon} alt="phone" className="m-form-password-icon" />
                                                <Form.Item
                                                    name="phone"
                                                    rules={[
                                                        { required: true, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                                                        { min: 3, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_REQUIRED' }) },
                                                        { min: 8, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) },
                                                        { max: 12, message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PHONE_INVALID' }) }]}>
                                                    <PhoneInputFloatingLabel phoneFocus={phoneFocus} setPhoneFocus={setPhoneFocus} setDialCode={setDialCode} />
                                                </Form.Item>
                                            </div>
                                            <div className="m-forgot-password-form-item">
                                                <img src={passwordIcon} alt="password" className="m-form-username-icon" />
                                                <Form.Item
                                                    name="otp"
                                                    rules={[
                                                        { required: true, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' }) },
                                                        {
                                                            max: 6, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' })
                                                        },
                                                    ]}>
                                                    <div
                                                        className="m-float-label"
                                                        onBlur={() => setOtpFocus(false)}
                                                        onFocus={() => setOtpFocus(true)}
                                                    >
                                                        <Input maxLength={6} value={otpInput} className="m-input m-otp-input" onChange={e => setOtpInput(e.target.value)} />
                                                        <label className={otpLabelClass}>{intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP' })}</label>
                                                        <div className={otpInputClass}></div>
                                                    </div>
                                                </Form.Item>
                                                <CodeRequestButton
                                                    type={VerificationType.PHONE}
                                                    hasError={otpValidationError ? true : requestOTPCodeError ? true : false}
                                                    requestStatus={requestStatus}
                                                    isPinMatched={true}
                                                    isRequestCode={isRequestCode}
                                                    setIsRequestCode={setIsRequestCode}
                                                />
                                                {/* <div className='otp-request-btn' onClick={requestStatus}><FormattedMessage id={'FORGOT_PASSWORD_OTP_REQUEST'} /></div> */}
                                            </div>
                                            <div className='m-forgot-password-next-btn' onClick={onSubmitOTP}><FormattedMessage id={'FORM_NEXT_BTN'} /></div>
                                        </Form>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="mobile-login-title">
                            <img className="mobile-login-logo" src={Logo} alt={intl.formatMessage({ id: "SEO_LOGO" })} />
                        </div>
                        <div className="mobile-login-divider"></div>
                        <Form
                            onFinish={onFinish}
                            form={form}>
                            <div className='mobile-login-form-content-container'>
                                <Form.Item
                                    name='mobile_login_username'
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: 'LOGIN_USERNAME_REQUIRED' }) },
                                        { min: 5, message: intl.formatMessage({ id: 'AUTH_INVALID_CODE' }) }
                                    ]}
                                >
                                    <Input placeholder={intl.formatMessage({ id: 'LOGIN_USERNAME_PLACEHOLDER' })} />
                                </Form.Item>
                            </div>
                            <div className='mobile-login-form-content-container'>
                                <Form.Item
                                    name='mobile_login_password'
                                    rules={[
                                        { required: true, message: intl.formatMessage({ id: 'PASSWORD_REQUIRED' }) },
                                        {
                                            pattern: /[A-Za-z0-9]{6,19}$/,
                                            message: intl.formatMessage({ id: 'AUTH_REGISTER_FORM_PASSWORD_INVALID' })
                                        }
                                    ]}
                                >
                                    <Input.Password placeholder={intl.formatMessage({ id: 'LOGIN_PASSWORD_PLACEHOLDER' })} />
                                </Form.Item>
                            </div>
                            <PrimaryButton loading={loading} additionalClassName='primary-btn' btnText={'LOGIN_LOGIN_BTN'} isFormBtn={true} onClick={() => { }} />
                            <div className='mobile-login-form-content-container'>
                                <div className='mobile-login-form-forgot' onClick={onForgotPasswordClick}>
                                    <FormattedMessage id='M_MENU_FORGOT_USERNAME_PASSWORD' values={{
                                        0: <a><FormattedMessage id='LOGIN_USERNAME_PLACEHOLDER' /></a>,
                                        1: <a><FormattedMessage id='LOGIN_PASSWORD_PLACEHOLDER' />?</a>
                                    }} />
                                </div>
                            </div>
                        </Form>
                    </>
                }
            </Modal>
        </>
    )
}

export default LoginModal;