import { gql } from "@apollo/client";

export const MEMBER_LIST = gql`
query members($pageNo:Int, $pageSize:Int!, $memberFilterInput:MemberFilterInput!){
    memberList(pageNo:$pageNo, pageSize: $pageSize, memberFilterInput:$memberFilterInput){
        count,
        members{
            id,
            userName,
            name,
            affiliate {
                id,
                userName,
                name
            },
            group {
                id,
                name
            },
            isActive,
            url,
            createdAt,
            totalDeposit,
            totalTurnover
        }
    }
}
`