//#region
// eg. downloadCSV(csv, fileName);
// csv - data return from graphql using json2csv return as string.
// fileName - any file name you want to when pass in as string.
export const downloadCSV = async (csv: any, fileName: string) => {
	const csv1 = "\ufeff" + csv;
	let downloadLink = document.createElement('a');
	let blob = new Blob([csv1]);
	let url = URL.createObjectURL(blob);
	downloadLink.href = url;
	downloadLink.download = `${fileName}.csv`;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}
//#endregion