
import "../../Dashboard.less";
import "./DashboardHome.less";
import copy from 'copy-to-clipboard';
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../../hooks/auth/AuthContext";
import moment from "moment";

const DashboardHome = () => {
    const { authState } = useAuth();
    const refLink = `${authState?.userProfile?.siteUrl}/home/referral/?refId=${authState?.userProfile?.referralCode ?? '-'}`;

    const onCopyClick = (text: string) => {
        copy(text);
    }

    return (
        <div className="dashboard-home-container">
            <div className="home-welcome"><b><FormattedMessage id='DASHBOARD_HOME_WELCOME' /></b>{authState?.userProfile?.name} </div>
            <div className="home-stat-card-container">
                <div className="stat-card">
                    <div className="stat-card-title">
                        <FormattedMessage id='DASHBOARD_HOME_LAST_UPDATED' />
                    </div>
                    <div className='stat-card-content-date'>
                        {authState?.userProfile?.lastUpdated ?
                            moment(authState?.userProfile?.lastUpdated).format('DD MMM YYYY') : '-'}
                        <div className="stat-card-time">
                            {authState?.userProfile?.lastUpdated ?
                                moment(authState?.userProfile?.lastUpdated).format('HH:MM A') : '-'}
                        </div>
                    </div>
                </div>
                <div className="stat-card">
                    <div className="stat-card-title">
                        <FormattedMessage id='DASHBOARD_HOME_TOTAL_MEMBER' />
                    </div>
                    <div className='stat-card-content'>
                        {authState?.userProfile?.affMember ?? 0}
                    </div>
                </div>
                <div className="stat-card">
                    <div className="stat-card-title">
                        <FormattedMessage id='DASHBOARD_HOME_CM_TOTAL' />
                    </div>
                    <div className='stat-card-content'>
                        {authState?.userProfile?.cmRegisteredAff ?? 0}
                    </div>
                </div>
                <div className="stat-card">
                    <div className="stat-card-title">
                        <FormattedMessage id='DASHBOARD_HOME_CM_ACTIVE' />
                    </div>
                    <div className='stat-card-content'>
                        {authState?.userProfile?.cmActiveAff ?? 0}
                    </div>
                </div>
            </div>
            <div className="home-promo-container">
                <div className="promo-title"><b><FormattedMessage id='DASHBOARD_HOME_AFF_PROMO_URL' /></b></div>
                <div className="promo-url">{refLink}</div>
                <div className="promo-copy-btn" onClick={() => { onCopyClick(refLink) }}><FormattedMessage id='DASHBOARD_HOME_COPY' /></div>
            </div>
        </div>
    );
};

export default DashboardHome;
