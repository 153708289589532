import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../../../hooks/auth/AuthContext';
import { SET_LANGUAGE, useLayout } from '../../../../hooks/layout/LayoutContext';
import '../GeneralHeader.less';
import { regionLanguageList } from './regionLanguageList';

interface Props {
    onLanguageClick: any;
    containerName: any;
}

const HeaderLanguageDropdown = ({ onLanguageClick, containerName }: Props) => {
    const { authState } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();

    return (
        <div className={containerName}>
            <div className='language-region-txt'><FormattedMessage id='M_MENU_REGION'/></div>
            <div className='language-list-container'>
                {
                    regionLanguageList.map((region: any) => {
                        return (((authState.isLogin && region.currency === authState.userProfile?.currency) ||
                            (!authState.isLogin)) &&
                            <div key={region?.currency} className='language-container'>
                                <img src={region?.icon} alt={region?.id} />
                                <div className='language-item-right'>
                                    <div className='language-country'>{region?.name}</div>
                                    {
                                        region.language.length > 0 && <div className={'language-lang'}>{
                                            region.language.map((language: any) => {
                                                return (authState.isLogin && layoutState.currency !== region?.currency ) ?
                                                    <div key={language} className={(language.toUpperCase() === 'EN' ) ? 'language-lang-item-selected' : 'language-lang-item'}
                                                        onClick={() => onLanguageClick(language, region?.currency)}>
                                                        <FormattedMessage id={`LANGUAGE_${language?.toString().toUpperCase()}_FULL`} />
                                                    </div>
                                                    :
                                                    <div key={language} className={(layoutState.locale === language && layoutState.currency === region?.currency) ? 'language-lang-item-selected' : 'language-lang-item'}
                                                        onClick={() => onLanguageClick(language, region?.currency)}>
                                                        <FormattedMessage id={`LANGUAGE_${language?.toString().toUpperCase()}_FULL`} />
                                                    </div>
                                            })
                                        }</div>
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
}

export default HeaderLanguageDropdown;