import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
query MyProfile{
    myProfile{
		id,
		email,
		userName,
		dob,
		phone,
		name,
		currency,
		countryOfBank,
		userBankAccounts,
		dobUpdated
    }
}
`

export const UPDATE_PROFILE = gql`
    mutation UpdateProfile($updateProfileInput:UpdateProfileInput!){ 
        updateProfile(updateProfileInput:$updateProfileInput){ 
            status, 
            id,
            a
        }
    }
`;