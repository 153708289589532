import { gql } from "@apollo/client";

export const COMM_REPORT = gql`
query commissionReport($pageNo:Int, $pageSize:Int!, $commissionReportFilterInput:CommissionReportFilterInput!){
    commissionReport(pageNo:$pageNo, pageSize: $pageSize, commissionReportFilterInput:$commissionReportFilterInput){
        count,
        commissionReport{
            id,
            aff {
                id,
                userName,
                name,
                createdAt
            },
            platformFee,
            bonusFee,
            rebateFee,
            totalWinLoss,
            amount, 
            status,
            activePlayer,
            commDate,
            totalRegistered,
            commRate,
            grossCommission,
            updatedAt
        }
    }
}
`