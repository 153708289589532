import { FormattedMessage } from "react-intl";
import iconEarth from '../../../assets/images/icon-earth.png';
import iconCalculator from '../../../assets/images/icon-calculator.png';
import iconThumbs from '../../../assets/images/icon-thumbs.png';


const Commission = () => {

    return (
        <div className="home-commission">
            <div className="home-commission-title"><FormattedMessage id='COMM_TITLE' /></div>
            <div className="home-commission-card-container">
                <div className="home-commission-card">
                    <div className="commission-card-img"><img alt="register" src={iconEarth} /></div>
                    <div className="commission-card-title"><FormattedMessage id='COMM_PARTNER_TITLE' /></div>
                    <div className="commission-card-content"><FormattedMessage id='COMM_PARTNER_CONTENT' /></div>
                </div>
                <div className="home-commission-card">
                    <div className="commission-card-prefix"><FormattedMessage id='COMM_UPTO_PREFIX' /></div>
                    <div className="commission-card-img"><img alt="register" src={iconCalculator} /></div>
                    <div className="commission-card-title"><FormattedMessage id='COMM_UPTO_TITLE' /></div>
                    <div className="commission-card-content"><FormattedMessage id='COMM_UPTO_CONTENT' /></div>
                </div>
                <div className="home-commission-card">
                    <div className="commission-card-img"><img alt="register" src={iconThumbs} /></div>
                    <div className="commission-card-title"><FormattedMessage id='COMM_USER_TITLE' /></div>
                    <div className="commission-card-content"><FormattedMessage id='COMM_USER_CONTENT' /></div>
                </div>
            </div>
        </div>
    )
}

export default Commission;