import { gql } from "@apollo/client";

export const GET_BANKS = gql`
query Banks{
    banks{
        id,
        name,
        imageUrl
    }
}
`

export const GET_UNREGISTER_BANKS = gql`
query UnregisterBanks{
    unregisterBanks{
        id,
        name,
        imageUrl
    }
}
`

export const GET_BANK_ACCOUNTS = gql`
query BankAccounts{
    bankAccounts{
        count,
        bankAccounts {
            id,
            aff {
                id
            },
            currencyId,
            bank {
                id,
                name,
                imageUrl
            },
            accountName,
            accountNumber,
            bankBranch
        }
    }
}
`

export const ADD_BANK = gql`
    mutation CreateBankAccount($createBankAccountInput: CreateBankAccountInput!) {
        createBankAccount(createBankAccountInput: $createBankAccountInput) {
            status
            id,
        }
    }
`;

export const DELETE_BANK = gql`
    mutation DeleteBankAccount($deleteBankAccountInput: DeleteBankAccountInput!) {
        deleteBankAccount(deleteBankAccountInput: $deleteBankAccountInput) {
            status
            id,
        }
    }
`;

export const REQUEST_OTP = gql`
mutation RequestOtp($verificationType:String!){
    requestOtp(verificationType:$verificationType){
        status,
    }
}
`;