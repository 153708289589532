
import { useMutation, useQuery } from '@apollo/client';
import { Form, Menu, Space } from 'antd';
import { forEach } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import useNotification from '../../../../../hooks/layout/useNotification';
import useSpinner from '../../../../../hooks/layout/useSpinner';
import ItemListPagination from '../../../../../shared/components/item-list/ItemListPagination';
import '../../../Dashboard.less';
import { ADD_BANK, DELETE_BANK, GET_BANKS, GET_BANK_ACCOUNTS, REQUEST_OTP } from '../DashboardWithdrawDetail.gql';
import ConfirmationDialog from '../../../../../shared/components/confirmation-dialog/ConfirmationDialog';
import '../DashboardWithdrawDetail.less';
import { useCookies } from 'react-cookie';
import { useAuth } from '../../../../../hooks/auth/AuthContext';
import { VerificationType } from '../../../../../shared/helpers/general-helpers';
import DashboardWithdrawAddBankModal from './DashboardWithdrawAddBankModal';

const DashboardWithdrawDetailBank = () => {
    const { authState } = useAuth();
    const { setLoading } = useSpinner();
    const { setSuccessNotification, setErrorNotification } = useNotification();
    const [pageNo, setPageNo] = useState(1);
    const [bankForm] = Form.useForm();
    const intl = useIntl();
    const [selectedBankName, setSelectedBankName] = useState('');
    const [selectedBankVal, setSelectedBankVal] = useState('');
    const [addBankModalVisible, setAddBankModalVisible] = useState(false);
    const { data: banksData, loading: banksLoading } = useQuery(GET_BANKS,
        { fetchPolicy: "no-cache" });
    const { data: bankAccountsData, loading: bankAccountsLoading, refetch } = useQuery(GET_BANK_ACCOUNTS,
        { fetchPolicy: "no-cache" });
    const [addNewBank, { data: addNewBankData, loading: addNewBankLoading, error: addNewBankError }]
        = useMutation(ADD_BANK, { errorPolicy: "all" });
    const [deleteBank, { data: deleteBankData, loading: deleteBankLoading, error: deleteBankError }]
        = useMutation(DELETE_BANK, { errorPolicy: "all" });
    const [bankList, setBankList] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [requestOTPCode, { data: requestOTPCodeData, loading: requestOTPCodeLoading, error: requestOTPCodeError }] = useMutation(REQUEST_OTP, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });
    const [otpInput, setOtpInput] = useState("");
    const [cookies] = useCookies();
    const [otpValidationError, setOtpValidationError] = useState(false);
    const [countdownTime, setCountdownTime] = useState<number>(179);
    const [isRequestCode, setIsRequestCode] = useState(false);
    const [requestedCode, setRequestedCode] = useState(false);

    useEffect(() => {
        if (addNewBankLoading || banksLoading || bankAccountsLoading || deleteBankLoading)
            setLoading(true);
        else
            setLoading(false); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addNewBankLoading, banksLoading, bankAccountsLoading, deleteBankLoading]);

    useEffect(() => {
        if (banksData && banksData.banks) {
            let bank: any = [];
            forEach(banksData.banks, (banks) => {
                bank.push({
                    value: banks.id,
                    name: banks.name
                })
            });

            setBankList(bank);
        }
    }, [banksData])

    useEffect(() => {
        if (addNewBankData && addNewBankData.createBankAccount && addNewBankData.createBankAccount.status) {
            if (addNewBankData.createBankAccount.status === 'SUCCESS') {
                setAddBankModalVisible(false);
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_ADD_BANK_SUCCESS' }));
                refetch();
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_${addNewBankData.createBankAccount.status}` }));
            }
        }
    }, [addNewBankData])

    useEffect(() => {
        if (deleteBankData && deleteBankData.deleteBankAccount && deleteBankData.deleteBankAccount.status) {
            if (deleteBankData.deleteBankAccount.status === 'SUCCESS') {
                setAddBankModalVisible(false);
                setSuccessNotification(intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_DELETE_BANK_SUCCESS' }));
                setSelectedId('');
                refetch();
            } else {
                setErrorNotification(intl.formatMessage({ id: `ERROR_${deleteBankData.deleteBankAccount.status}` }));
            }
        }
    }, [deleteBankData])


    const onFinish = (fieldsValue: any) => {
        if (!selectedBankVal) {
            setHasError(true);
            setErrorMessage('DASHBOARD_WITHDRAW_DETAIL_BANK_REQUIRED');
        } else {
            const variables: any = {
                createBankAccountInput: {
                    bankId: selectedBankVal,
                    accountName: fieldsValue.wd_acc,
                    accountNumber: fieldsValue.wd_acc_num,
                    withdrawalMethod: 'BANK_TRANSFER',
                    verificationCode: fieldsValue.otp ?? '',
                    verificationType: VerificationType.PHONE
                },
            };

            addNewBank({ variables })
        }
    }

    const renderBankDropdown = (bankList: any[]) => {
        return <Menu
            className="dashboard-wd-bank-menu">
            {bankList.map((bank) => {
                return <Menu.Item key={bank.value} onClick={() => menuItemClick(bank.name, bank.value)} >
                    <div className='wd-bank-child-menu'>
                        <div className='wd-bank-child-menu-name'>{bank.name}</div>
                    </div>
                </Menu.Item>
            })}
        </Menu>;
    }

    const menuItemClick = (name: string, value: string) => {
        setSelectedBankName(name);
        setSelectedBankVal(value);
    }

    const deleteBankClick = () => {
        if (selectedId) {
            const variables: any = {
                deleteBankAccountInput: {
                    bankAccountId: selectedId,
                },
            };

            deleteBank({ variables });
        }
    }

    const columns: any = [
        {
            title: intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_COL_BANK' }),
            dataIndex: 'bank',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            (text && text?.name) ?
                                text.name :
                                '-'
                        }
                    </Space>
                )
            }
        },
        // {
        //     title: intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_COL_BANK_BRANCH' }),
        //     dataIndex: 'bankBranch',
        //     render: (text: any) => {
        //         return (
        //             <Space size="middle">
        //                 {
        //                     text ?? '-'
        //                 }
        //             </Space>
        //         )
        //     }
        // }, 
        {
            title: intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_COL_ACC' }),
            dataIndex: 'accountName',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text ?? '-'
                        }
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'DASHBOARD_WITHDRAW_DETAIL_COL_ACC_NUM' }),
            dataIndex: 'accountNumber',
            render: (text: any) => {
                return (
                    <Space size="middle">
                        {
                            text ?? '-'
                        }
                    </Space>
                )
            }
        }, {
            title: intl.formatMessage({ id: 'TABLE_COL_ACTION' }),
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (row: any) => <div className='wd-action-col' onClick={() => { setSelectedId(row.id); setShowModal(true); }}><AiOutlineDelete /></div>,
        },
    ]

    const handleCancel = () => {
        setAddBankModalVisible(false);
    };

    const addBank = () => {
        setAddBankModalVisible(true);
    }

    const requiredRules = (input: string) => {
        let msgId = '';
        switch (input) {
            case 'wd_acc':
                msgId = 'DASHBOARD_WITHDRAW_DETAIL_ACCOUNT_REQUIRED';
                break;
            case 'wd_acc_num':
                msgId = 'DASHBOARD_WITHDRAW_DETAIL_ACCOUNT_NUM_REQUIRED';
                break;
        }

        return [
            { required: true, message: intl.formatMessage({ id: msgId }) },
        ]
    }

    let otpRules: any = [
        { required: true, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' }) },
        {
            max: 6, message: intl.formatMessage({ id: 'FORGOT_PASSWORD_OTP_REQUIRED' })
        },
    ]

    const requestStatus = () => {
        let phone = authState?.userProfile?.phone ?? '';

        if (!phone) {
            setErrorNotification(intl.formatMessage({ id: 'OTP_VERIFICATION_ERROR_REGISTER' }));
            setOtpValidationError(true);
        } else {
            setOtpValidationError(false);
            setRequestedCode(true);
            requestOTPCode({
                variables: {
                    verificationType: VerificationType.PHONE,
                },
            });
        }
    };

    useEffect(() => {
        if (requestOTPCodeData) {
            if (requestOTPCodeData.requestOtp.status !== "SUCCESS") {
                if (requestOTPCodeData.requestOtp.status === 'PHONE_NUMBER_EXIST') {
                    setErrorNotification(intl.formatMessage({ id: 'PHONE_NUMBER_EXIST' }));
                }
                else {
                    setErrorNotification(intl.formatMessage({ id: "RESET_PASSWORD_FAILED" }));
                }
            } else {
                const cookiesCountdownTimer = cookies[`c-cooldown-start-${VerificationType.PHONE}`];

                if (cookiesCountdownTimer) {
                    setCountdownTime(+cookiesCountdownTimer);
                }

                setIsRequestCode(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestOTPCodeData]);

    return (
        <>
            <div className='dashboard-wd-container'>
                <div className='dashboard-wd-add-bank-container'>
                    <div className='dashboard-wd-add-bank-btn' onClick={addBank}>
                        <AiOutlinePlus />
                        <div className='dashboard-wd-add-bank-txt'>
                            <FormattedMessage id={'DASHBOARD_WITHDRAW_DETAIL_ADD'} />
                        </div>
                    </div>
                </div>
                <ItemListPagination pageNo={pageNo} setPageNo={setPageNo} columns={columns} data={bankAccountsData?.bankAccounts?.bankAccounts || []}
                    total={bankAccountsData?.bankAccounts?.count ?? 0} />
            </div>
            <ConfirmationDialog showModal={showModal} title={intl.formatMessage({ id: 'DELETE_BANK_ACCOUNT_TITLE' })}
                confirmationMessage={intl.formatMessage({ id: 'DELETE_BANK_ACCOUNT_MESSAGE' })}
                setShowModal={setShowModal}
                action={deleteBankClick} actionText={intl.formatMessage({ id: 'DASHBOARD_MESSAGING_DELETE' })} />
            <DashboardWithdrawAddBankModal modalVisible={addBankModalVisible} setModalVisible={setAddBankModalVisible} refetch={refetch} />
        </>
    )
}

export default DashboardWithdrawDetailBank;