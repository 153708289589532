
import '../../Dashboard.less';
import DashboardTopInnerMenu from '../../shared/dashboard-top-inner-menu/DashboardTopInnerMenu';
import DashboardMenuTitle from '../dashboard-menu-title/DashboardMenuTitle';
import DashboardWithdraw from '../withdraw/DashboardWithdraw';
import DashboardWithdrawDetailBank from './components/DashboardWithdrawDetailBank';
import './DashboardWithdrawDetail.less';
interface Props {
    setActiveTab: any;
}

const DashboardWithdrawDetail = ({setActiveTab}: Props) => {
    const changePasswordTabList = [
        {
            tabName: 'DASHBOARD_WITHDRAW_DETAIL_TAB_BANK',
            key: 'bank',
            element: <DashboardWithdrawDetailBank />
        },
        {
            tabName: 'DASHBOARD_MENU_WITHDRAW',
            key: 'crypto',
            element: <DashboardWithdraw setActiveTab={setActiveTab} />
        }
    ]
    return (
        <div className="dashboard-withdraw-detail-container">
            <DashboardMenuTitle text={'DASHBOARD_WITHDRAW_DETAIL_TITLE'} />
            <DashboardTopInnerMenu elementList={changePasswordTabList} />
        </div>
    )
}

export default DashboardWithdrawDetail;