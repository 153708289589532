
import { Drawer, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import iconProduct from '../../../../assets/images/icon-menu-product.svg';
import iconCommPlans from '../../../../assets/images/icon-menu-plans.svg';
import iconFaq from '../../../../assets/images/icon-menu-faq.svg';
import iconTNC from '../../../../assets/images/icon-menu-terms.svg';
import iconContactUs from '../../../../assets/images/icon-menu-contactus.svg';
import iconLogout from '../../../../assets/images/icon-menu-logout.svg';
import LangThaiFlag from '../../../../assets/images/language-thailand.png';
import LangEngFlag from '../../../../assets/images/language-uk.png';
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import useSpinner from "../../../../hooks/layout/useSpinner";
import { SET_LANGUAGE, useLayout } from "../../../../hooks/layout/LayoutContext";
import { CLEAR_CURRENT_USER, SET_LOGIN_STATUS, useAuth } from "../../../../hooks/auth/AuthContext";
import { LOGOUT } from "../../../../pages/auth/auth.gql";
import { clearAllTokens } from "../../../helpers/set-token-cookie.helper";
import '../GeneralHeader.less';

interface Props {
    isDrawerVisible: boolean;
    setIsDrawerVisible: any;
}

const HeaderMobileDrawer = ({ isDrawerVisible, setIsDrawerVisible }: Props) => {
    const navigate = useNavigate();
    const { setLoading } = useSpinner();
    const { layoutState, layoutDispatch } = useLayout();
    const { authState, authDispatch } = useAuth();
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [languageModalVisible, setLanguageModalVisible] = useState(false);

    const setDisplayLanguageFlag = () => {
        switch (layoutState.locale) {
            case 'en':
                return LangEngFlag;
            case 'th':
                return LangThaiFlag;
            default:
                return LangEngFlag;
        }
    }

    const onClose = () => {
        setIsDrawerVisible(false);
    };

    useEffect(() => {
        if (logoutLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading])

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    const onMenuItemClick = (menu: string) => {
        setIsDrawerVisible(false);
        switch (menu) {
            case 'tnc':
                navigate('/home/terms');
                break;
            case 'contactus':
                navigate('/home/contact-us');
                break;
            case 'logout':
                logout();
                break;
            case 'language':
                setLanguageModalVisible(true);
                break;
            case 'faq':
                navigate('/home/faq');
                break;
            case 'products':
                navigate('/home/product');
                break;
            case 'commplans':
                navigate('/home/commission-plans');
                break;
        }
    }

    const handleCancel = () => {
        setLanguageModalVisible(false);
    }

    const onChangeLanguage = (language: string) => {
        localStorage.setItem('pfxl', language);
        layoutDispatch({ type: SET_LANGUAGE, payload: language });
        setLanguageModalVisible(false);
    }

    return (
        <>
            <Drawer width={300} placement="right" onClose={onClose} visible={isDrawerVisible}>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('commplans')}>
                    <img src={iconCommPlans} alt="promotion" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_COMM_PLAN' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('faq')}>
                    <img src={iconFaq} alt="FAQ" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_FAQ' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('contactus')}>
                    <img src={iconContactUs} alt="contact us" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_CONTACT_US' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('tnc')}>
                    <img src={iconTNC} alt="TNC" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_TNC' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('products')}>
                    <img src={iconProduct} alt="TNC" className="footer-mobile-icon" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_PRODUCT' /></div>
                </div>
                <div className='mobile-menu-item' onClick={() => onMenuItemClick('language')}>
                    <img className="header-language-img" src={setDisplayLanguageFlag()} alt="language" />
                    <div className="mobile-menu-item-txt"><FormattedMessage id='M_MENU_REGION' /></div>
                </div>
                {/* {authState.isLogin &&
                    <div className='mobile-menu-item' onClick={() => onMenuItemClick('logout')}>
                        <img src={iconLogout} alt="logout" className="footer-mobile-icon" />
                        <div className="mobile-menu-item-txt"><FormattedMessage id='LOGOUT_BTN' /></div>
                    </div>
                } */}
            </Drawer>
            <Modal
                className="m-drawer-language-modal"
                visible={languageModalVisible}
                closable={true}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose
            >
                <div className="m-drawer-language-title"><FormattedMessage id='M_SELECT_LANGUAGE' /></div>
                <div className="m-drawer-language-container">
                    <div className="m-drawer-language-options" onClick={() => { onChangeLanguage('en') }}>
                        <img className="m-drawer-language-img" src={LangEngFlag} alt="" />
                        <div className="m-drawer-language-txt"><FormattedMessage id='LANGUAGE_EN_FULL' /></div>
                    </div>
                    <div className="m-drawer-language-options" onClick={() => { onChangeLanguage('th') }}>
                        <img className="m-drawer-language-img" src={LangThaiFlag} alt="" />
                        <div className="m-drawer-language-txt"><FormattedMessage id='LANGUAGE_TH_FULL' /></div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default HeaderMobileDrawer;
