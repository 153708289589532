import { gql } from "@apollo/client";

export const SUBMIT_WITHDRAWAL = gql`
    mutation CreateWithdraw($createWithdrawInput: CreateWithdrawInput!) {
        createWithdraw(createWithdrawInput: $createWithdrawInput) {
            status
            id,
        }
    }
`;

export const WITHDRAW_HISTORY = gql`
    query WithdrawHistory($pageNo: Int!, $pageSize: Int!, $historyInput:HistoryInput){
        withdrawHistory(pageNo:$pageNo, pageSize:$pageSize, historyInput:$historyInput){
            count(historyInput:$historyInput),
            withdraw {
                id,
                bankAccount {
                    id,
                    withdrawalMethod
                }
                amount,
                status,
                createdAt,
                approvedAt
            }
        }
    }
`;

export const GET_WALLETS = gql`
query Wallets{
    wallets{
        wallets{
            id,
            walletType,
            userWalletType{
                id,
                displayName,
                displayProductId,
                gameProvider {
                    id,
                    name,
                    code
                },
                gameProviderCodeId
            }
            balance,
            depositable,
            withdrawable,
            isMaintenance,
            isBonus
        },
        userBonusTracker{
            userDepositBonus{
                bonusSetting{
                    BonusSettingWalletType{
                        walletType{
                            id
                        }
                    }
                }
            }
        },
        walletCategory {
            seq,
            productName,
            labelName,
            bal
        }
    }
}
`;