import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import { useLazyQuery } from '@apollo/client';
// import { downloadCSV } from '../../../shared/helpers/csv-download.helper';
// import { GET_USERS_EXPORT } from './Account.gql';
import moment from 'moment';
import { endOfDay, startOfDay, subDays, subMonths } from 'date-fns';
import { GET_GAME_PROVIDERS, GET_PRODUCT, GET_WIN_LOSS_REPORT_DETAILS_EXPORT } from './WinLossDetail.gql';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { downloadCSV } from '../../../../shared/helpers/csv-download.helper';
import useNotification from '../../../../hooks/layout/useNotification';
import useSpinner from '../../../../hooks/layout/useSpinner';
const { RangePicker } = DatePicker;

interface Props {
    setFilters: Dispatch<SetStateAction<{
        userName: string, product: string, gameProvider: string, gameCode: string,
        status: string, roundId: string, startDate: Date, endDate: Date
    }>>;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
    filters: { userName: string, product: string, gameProvider: string, gameCode: string, startDate: Date, endDate: Date }
}

const WinLossDetailFilter = ({ setFilters, filters, setPageNo }: Props) => {
    const [queryForm] = Form.useForm();
    const { setLoading } = useSpinner();
    const location = useLocation();
    const { setErrorNotification } = useNotification();
    const defaultDates: any = [moment().subtract(1, "months"), moment()];
    const [dates, setDates] = useState(defaultDates);
    const [products, setProducts] = useState([]);
    const [gameProvider, setGameProvider] = useState([]);
    const onFinish = (fieldsValue: any) => {
        emitFilter(fieldsValue.userName, fieldsValue.product, fieldsValue.gameProvider, fieldsValue.gameCode,
            fieldsValue.datePicker, fieldsValue.status, fieldsValue.roundId);
    }

    const [getGameProviders, { data }] = useLazyQuery(GET_GAME_PROVIDERS, { fetchPolicy: 'network-only' });
    const [getProducts, { data: productData }] = useLazyQuery(GET_PRODUCT, { fetchPolicy: 'network-only' });

    const [getExportCSVPayload, { data: getData, loading: getLoading }] = useLazyQuery(GET_WIN_LOSS_REPORT_DETAILS_EXPORT, { fetchPolicy: 'network-only' });

    const exportFile = () => {
        try {
            const minExportDate = startOfDay(subMonths(new Date(filters.endDate), 3));
            if (new Date(filters.startDate) < minExportDate) {
                setErrorNotification(`Action not allowed. The Export date limitation is 3 months only.`);
                return;
            }
            getExportCSVPayload({ variables: { winLossReportDetailInput: filters } });
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (getData) {
            const exportCSVPayload = getData && getData.winLossReportDetailsExport ? getData.winLossReportDetailsExport : null;
            if (exportCSVPayload) {
                downloadCSV(exportCSVPayload.csv, "Win/Loss Detail Report");
            }
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData]);

    useEffect(() => {
        setLoading(getLoading); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLoading]);

    useEffect(() => {
        getGameProviders();
        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data) {
            setGameProvider(data.gameProviders);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (productData) {
            setProducts(productData.products);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData]);

    const reset = () => {
        queryForm.resetFields();
        emitFilter('', '', '', '', '', '', '');
    }

    const emitFilter = (userName: string, productVal: string, gameProviderVal: string, gameCode: string,
        datePicker: string, status: string, roundId: string) => {
        const startDate = datePicker && datePicker.length > 0 ?
            new Date(datePicker[0]) :
            new Date(moment().startOf('month').toString());
        const endDate = datePicker && datePicker.length > 0 ?
            new Date(datePicker[1]) :
            endOfDay(new Date());

        setFilters({
            userName: userName || '',
            product: productVal || '',
            gameProvider: gameProviderVal || '',
            gameCode: gameCode || '',
            startDate: startDate || '',
            endDate: endDate || '',
            roundId: roundId || '',
            status: status || '',
        });
        setPageNo(1);
    }

    const onOpenChange = (open: any) => {
        if (open) {
            //setHackValue([]);
            setDates([]);
        } else {
            //setHackValue(null);
        }
    };

    const disabledDate = (current: any) => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 61;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 61;
        return tooEarly || tooLate;
    };

    return (
        <>
            <Form layout="inline"
                form={queryForm}
                onFinish={onFinish}
                className="filter-panel"
                initialValues={{
                    product: "", gameProvider: "", datePicker: [moment(startOfDay(subDays(new Date(), 2))), moment(endOfDay(new Date()))],
                    status: ''
                }}
            >
                <Form.Item label="Bet Date" name="datePicker">
                    <RangePicker
                        showTime={true}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates(val)}
                        onChange={val => setDates(val)}
                        onOpenChange={onOpenChange}
                        showSecond={false}
                        format={"yyyy-MM-DD HH:mm"}
                    />
                </Form.Item>
                <Form.Item label="Member ID" name="userName">
                    <Input placeholder="Input Member ID" />
                </Form.Item>
                <Form.Item label="Game Provider" name="gameProvider">
                    <Select style={{ width: 150 }}>
                        <Select.Option value="">All</Select.Option>
                        {
                            gameProvider?.map((d: any) => {
                                return (<Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Product" name="product">
                    <Select style={{ width: 150 }}>
                        <Select.Option value="">All</Select.Option>
                        {
                            products?.map((d: any) => {
                                return (<Select.Option key={d.id} value={d.id}>{d.id}</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Status" name="status">
                    <Select style={{ width: 150 }}>
                        <Select.Option value="">All</Select.Option>
                        <Select.Option value="RUNNING">Running</Select.Option>
                        <Select.Option value="SETTLED">Settled</Select.Option>
                        <Select.Option value="UNSETTLED">Unsettled</Select.Option>
                        <Select.Option value="CANCELLED">Cancelled</Select.Option>
                        <Select.Option value="REJECTED">Rejected</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Game Code" name="gameCode">
                    <Input placeholder="Input Game Code" />
                </Form.Item>
                <Form.Item label="Round ID" name="roundId">
                    <Input placeholder="Input Round ID" />
                </Form.Item>
                <Form.Item className="filter-button-group">
                    <Button type="primary" htmlType="submit"><FormattedMessage id='FILTER_BTN_SEARCH' /></Button>
                    <Button type="primary" onClick={exportFile}><FormattedMessage id='FILTER_BTN_EXPORT' /></Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default WinLossDetailFilter;