
import { Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import '../../Dashboard.less';
import './DashboardMenuTitle.less';

interface Props {
    text: string;
}

const DashboardMenuTitle = ({text}: Props) => {
    return (
        <div className="dashboard-menu-title-container">
            <div className='dashboard-menu-title'><FormattedMessage id={text} /></div>
            {/* <Divider className='dashboard-menu-divider' /> */}
        </div>
    )
}

export default DashboardMenuTitle;