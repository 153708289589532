import { Button, DatePicker, Form } from "antd";
import moment from "moment";
import { endOfDay, format, startOfDay, subMonths, endOfMonth, startOfMonth } from "date-fns";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";
import './TablePageFilter.less';

const { RangePicker } = DatePicker;

interface Props {
    setFilters: Dispatch<SetStateAction<{ startDate: string; endDate: string }>>;
}

const TablePageFilter = ({ setFilters }: Props) => {
    const [queryForm] = Form.useForm();
    const onFinish = (fieldsValue: any) => {
        emitFilter(fieldsValue.datePicker);
    };

    const reset = () => {
        queryForm.resetFields();
        emitFilter([]);
    };

    const emitFilter = (datePicker: any[]) => {
        const startDate =
            datePicker && datePicker.length > 0
                ? format(startOfDay(new Date(datePicker[0])), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
                : format(startOfDay(subMonths(startOfMonth(new Date()), 1)), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        const endDate =
            datePicker && datePicker.length > 0
                ? format(endOfDay(endOfMonth(new Date(datePicker[1]))), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
                : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
;
        setFilters({
            startDate,
            endDate,
        });
    };

    return (
        <div className="filter-container">
            <Form
                layout="inline"
                form={queryForm}
                onFinish={onFinish}
                initialValues={{ datePicker: [moment(subMonths(startOfMonth(new Date()), 1)), moment()] }}
                className="filter-panel"
            >
                <Form.Item name="datePicker" label={<FormattedMessage id="CREATED_AT" />}>
                    <RangePicker picker="month" disabledDate={(current) => {
                        return moment() <= current;
                    }} />
                </Form.Item>
                <Form.Item className="filter-button-group">
                    <Button type="primary" htmlType="submit">
                        <FormattedMessage id="BTN_SEARCH_LOWER" />
                    </Button>
                    <Button type="primary" onClick={reset} danger>
                        <FormattedMessage id="FILTER_BTN_RESET" />
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default TablePageFilter;
