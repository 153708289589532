
export const buildProductListBySeq = (productName: string) => {
    switch (productName) {
        case 'SPORTBOOK':
            return {
                seq: 2,
                productName,
                labelName: 'DASHBOARD_TRANSFER_SPORTS'
            };
        case 'ESPORT':
            return {
                seq: 3,
                productName,
                labelName: 'DASHBOARD_TRANSFER_ESPORTS'
            };
        case 'LIVECASINO':
            return {
                seq: 4,
                productName,
                labelName: 'DASHBOARD_TRANSFER_CASINO'
            };
        case 'SLOT':
            return {
                seq: 5,
                productName,
                labelName: 'DASHBOARD_TRANSFER_SLOTS'
            };
        case 'VIRTUALGAMES':
            return {
                seq: 6,
                productName,
                labelName: 'DASHBOARD_TRANSFER_VGAMES'
            };
        case 'FISHHUNTER':
            return {
                seq: 7,
                productName,
                labelName: 'DASHBOARD_TRANSFER_FISH'
            };
        case 'LOTTERY':
            return {
                seq: 8,
                productName,
                labelName: 'DASHBOARD_TRANSFER_LOTTERY'
            };
        default:
            return {
                seq: 999,
                productName,
                labelName: 'DASHBOARD_TRANSFER_WALLET_LIST'
            }
    }
}

export enum VerificationType {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    PIN = 'PIN'
}

export const checkIsGameAvailable = (game: any, maintenanceList: any) => {
    return !checkIsGameComingSoon(game) && !checkIsGameUnderMaintenance(game, maintenanceList);
}

export const checkIsGameComingSoon = (game: any) => {
    return game.url ? false : true;
}

export const checkIsGameUnderMaintenance = (game: any, maintenanceList: any) => {
    return (maintenanceList.filter((x: string | undefined) => x === game.providerId).length > 0 || game.id === 'fishingpp') ? true : false;
}

export const getCountryIdByCurrency = (currency: any) => {
    switch (currency) {
        case 'SGD':
            return 'SG';
        case 'PHP':
            return 'PH';
        case 'MYR':
            return 'MY';
        default:
            return 'TH';
    }
}

export const checkLocation = async () => {
    const url = process.env.REACT_APP_LOCATION ?? '';

    const res = await fetch(url, {
        method: 'GET'
    });
    const result = await res.json();
    if (result) {
        console.log('result', result);
        return result.location
    } else {
        return "";
    }
}