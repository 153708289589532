
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { setDBLanguageToGet } from "../../../shared/helpers/language.helpers";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const Banner = ({ bannerData }: any) => {
    const navigate = useNavigate();

    const onRegClick = () => {
        navigate('/home/register');
    }
    
    return (
        <div className="home-banner">
            {//bannerData && bannerData.length > 0 ?
                <Swiper
                    initialSlide={1}
                    autoplay={true}
                    grabCursor={true}
                    slidesPerView={1}
                    spaceBetween={0}
                    slidesPerGroup={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {bannerData && bannerData.banners?.map((banner: any) => {
                        return (
                            <SwiperSlide key={banner.id}>
                                <div key={banner.id} className='banner-card'>
                                    <img alt={banner.title} className="carousel-image"
                                        src={
                                            banner?.pictureUrl
                                            //JSON.parse(banner?.pictureUrl)[setDBLanguageToGet(layoutState.locale)]
                                        } />
                                    <div className="banner-text">{banner?.bannerTxt}</div>
                                    <div className="banner-btn" onClick={onRegClick}><FormattedMessage id='BTN_EARN'/></div>
                                </div>
                            </SwiperSlide>

                        );
                    })}
                </Swiper>
                //:
                // <Spin />
            }
        </div>
    )
}

export default Banner;