import { DatePicker, Form, Modal } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
    modalVisible: { visible: boolean, setVisible: Dispatch<SetStateAction<boolean>> };
    updateProfile: any;
}

const DashboardProfileEdit = ({ modalVisible, updateProfile }: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();


    const handleOk = () => {
        form.validateFields()
            .then(values => {
                updateProfile({ variables: { updateProfileInput: { dob: values.dob } } });
                //modalVisible.setVisible(false);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const handleCancel = () => {
        modalVisible.setVisible(false);
    };

    return (
        <>
            <Modal
                className="profile-edit-modal"
                visible={modalVisible.visible}
                closable={true}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelText={intl.formatMessage({ id: "CANCEL_BTN" })}
                okText={intl.formatMessage({ id: "SAVE_BTN" })}
                destroyOnClose
            >
                <div className="profile-edit-title"><FormattedMessage id='DASHBOARD_PROFILE_EDIT_TITLE' /></div>
                <Form form={form}>
                    <Form.Item name="dob" label={intl.formatMessage({ id: 'DASHBOARD_PROFILE_EDIT_DOB' })}
                        rules={[
                            { required: true, message: intl.formatMessage({ id: 'DASHBOARD_PROFILE_DOB_REQUIRE' }) }]}>
                        <DatePicker
                            disabledDate={(current) => {
                                return moment().add(-18, 'years') < current ||
                                    moment() <= current;
                            }}
                        />
                    </Form.Item>
                </Form>
                <div className="profile-edit-remark"><FormattedMessage id='DASHBOARD_PROFILE_EDIT_REMARK' /></div>
            </Modal>
        </>
    );
};
export default DashboardProfileEdit;
