
import copy from 'copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import './PrimaryButton.less';

interface Props {
    copyText: string;
    copyFrom: string;
}

const CopyButton = ({ copyText, copyFrom }: Props) => {

    const onCopyClick = (text: string, copyFrom: string) => {
        copy(text);
    }

    return (
        <div className='copy-button' onClick={() => { onCopyClick(copyText, copyFrom) }}>
            <FaCopy />
        </div>
    )
}

export default CopyButton;